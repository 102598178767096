import axios from 'axios';

const authorisation = localStorage.getItem('authorisation');
const axiosClient = axios.create({
    baseURL: `https://mct-demo.dynajobs.com/api`,
    headers: {
        'Authorization': 'DYNAService '+authorisation
    }
});

export default axiosClient;