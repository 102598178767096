import {Link, useHistory} from "react-router-dom";
import FooterAdmin from "components/Footers/FooterAdmin";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Welcome from "components/Sections/WelcomeSection";
import { useTranslation } from "react-i18next";
import JobHealthButtons from "./JobHealthButtons";
import {useState} from "react";


const JobHealthRestrictions = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation('site');
    const changeLanguageHandler = (e) =>
    {
        i18n.changeLanguage(e.target.value)
    }
    const [link, setLink] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [action, setAction]= useState('');
    const [goToButton, setGoToButton] = useState('');
    const [enableButtons, setEnableButtons] = useState(true);
    return (
        <>
            <div className="relative bg-blueGray-100">
            <AdminNavbar title="create resume" />
                <div className="bg-lightBlue-600 md:pt-28 pt-12">
                    <div className="container mx-auto">
                        <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center text-white text-xl cursor-pointer" onClick={()=> {
                                if(!enableButtons) {
                                    setLink('/matching-jobs')
                                    setGoToButton(<button
                                        className={`w-full   border-2 border-solid border-blue-600 bg-white text-center text-blue-600 py-2`}
                                        onClick={() => setAction('exit')}>
                                        <span>{t('Back to select jobs')}</span>
                                    </button>)
                                    setShowModal(true);
                                }else {
                                    history.push('/matching-jobs')
                                }
                            }}>
                                <i className="fa fa-chevron-left"></i>
                                {t('back')}
                            </div>
                            <div className="flex justify-between items-center mb-4">

                                <div><select className=" placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline font-semibold" onChange={e=>changeLanguageHandler(e)} value={i18n.language.substring(0, 2)}>
                                    <option value="de">{t('german')}</option>
                                    <option value="en">{t('english')}</option>
                                    <option value="uk">{t('ukranian')}</option>
                                    <option value="fr">{t('french')}</option>
                                    <option value="it">{t('italian')}</option>
                                    <option value="ar">{t('arabic')}</option>
                                    <option value="pt">{t('portuguese')}</option>
                                    <option value="ur">{t('urdu')}</option>
                                    <option value="zh">{t('chinese')}</option>
                                </select></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <JobHealthButtons link={link} setLink={setLink} setAction={setAction} action={action} setShowModal={setShowModal} showModal={showModal} goToButton={goToButton} setGoToButton={setGoToButton} enableButtons={enableButtons} setEnableButtons={setEnableButtons} />
            
            <FooterAdmin />
        </>
    )
}

export default JobHealthRestrictions;