import React from "react";
import { useLocation } from "react-router-dom";
import UploadCV from "components/Modals/UploadCV";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { addJob } from '../../redux/reducer/jobListReducer';
import { addJobOptions, checkJobOption, clearCheckedJobOptions } from '../../redux/reducer/jobOptionsReducer';
import { addTrainingList } from '../../redux/reducer/trainingListReducer';
import {
    setCvId,
    setJobId,
    setTrainingId,
    setResultNumber,
    setShowLoader, 
    setCvRubricationData, 
    setJobRubricationData,
    setTrainingRubricationData
} from '../../redux/reducer/searchReducer';
import axiosClient from "api/axiosClient";
import missingItems from "utils/missingItems";
import { setCheckedQualifications } from "redux/reducer/updatedCvsReducer";
import { useTranslation } from "react-i18next";
import JobLoading from '../Loadings/jobLoading'




function SearchSection({ headers = [], showTraining = false }) {

    const [showAdvancedSearch, setShowAdvancedSearch] = React.useState(false);
    const [showJobLoader, setShowJobLoader] = React.useState(false);
    const [selectedJobs, setSelectedJobs] = React.useState([]);
    const {t,i18n}=useTranslation('site');
    let lang = i18n.language;
    lang = i18n.language.substring(0, 2).toUpperCase();

    
    const [jobTitle, setJobTitle] = React.useState("");
    const dispatch = useDispatch();
    let {
        cvId,
        jobId,
        trainingId,
        resultNumber, 
        cvRubricationData
    } = useSelector(state => state.searchInput,shallowEqual);
    const cvList = useSelector(state=>state.cvList,shallowEqual);
    const jobList = useSelector(state => state.jobList,shallowEqual);
    const jobOptions = useSelector(state => state.jobOptions, shallowEqual);
    const trainingList = useSelector(state => state.trainingList,shallowEqual);
    const jobs = jobList.find(j => j.cvId === cvId);
    // const trainings = trainingList.find(t => t.cvId === cvId && t.jobId === jobId);
    const updatedCvs = useSelector(state => state.updatedCvs, shallowEqual);
    



    const getCvRubrication = (cvId) => {
        dispatch(setShowLoader(true));
        axiosClient.get(`/Rubrication/GetCVRubricationById?cvid=${cvId}`).then(res => {
                const { rubrication_items } = res.data;
                dispatch(setCvRubricationData(rubrication_items));
                dispatch(setShowLoader(false));
        }).catch(err => {
            dispatch(setCvRubricationData([]));
            dispatch(setShowLoader(false));
        }); 
    }

    const jobMatchingHandler = (cvId) => {
        const selectedJobs = jobList.find(item => item.cvId === cvId);

        if (!selectedJobs ) {
            findJobs(cvId);
        }
    }

    
    const trainingMatchingHandler = (cvId,jobId) => {
        
        // if (!trainings) {
        //     findTrainings(cvId,jobId);
        // }
        findTrainings(cvId,jobId);
    }

    const findJobs = (cvId) => {
        dispatch(setShowLoader(true));
        setShowJobLoader(true);
        const body = {
            ref_profile_id: cvId,
            actuality: -60,
            affiliation_filter: "NotFiltered",
            employment_filter: "NotFiltered",
            languages: [
                lang
            ],
            language: lang,
            fallback_language: "DE"
        };
        axiosClient.post(`/Matching/GetJobMatch`, body).then(res => {
            if (res.status === 200) {
                if (res.data && res.data.length) {
                    let jobs = {
                        cvId,
                        jobList: []
                    };
                    let firstJobId = '';
                    res.data.map((data, index) => {
                        const { title, company, target_profile_id, ranking_score, city, position } = data;
                        const job = {
                            title,
                            company,
                            target_profile_id,
                            ranking_score,
                            city,
                            position
                        }
                        jobs.jobList.push(job);
                        if (index === 0) {
                            firstJobId = target_profile_id;
                        } 
                        
                        
                    })
                    dispatch(addJob(jobs));
                    dispatch(addJobOptions(jobs.jobList));
                    dispatch(setJobId(firstJobId));
                    const select_job = document.querySelector('#job_list');
                    select_job.value = firstJobId;
                    select_job.dispatchEvent(new Event('change', { bubbles: true }));
                    
                } 
            } else {
                dispatch(setShowLoader(false));
            }
            setShowJobLoader(false);
        }).catch(err => {
            dispatch(setShowLoader(false));
        });
    }

    const findTrainings = (cvId, jobId) => {
        
        dispatch(setShowLoader(true));
        const updatedCv = updatedCvs.find(d => d.uniqueId === cvId + jobId);
        const plusText = updatedCv ? updatedCv.plusText : '';
        const body = {
            "ref_profile_id": "",
            "exclude_pooled_items": true,
            "selected_cv_id": cvId,
            "selected_job_id": jobId,
            "pre_selection": {
              "cost_expense": 50000,
              "time_expense": 50000,
              "training_filter": "NotFiltered",
                "affiliation": "NotFiltered",
              "plus_text": plusText
            },
            "languages": [
                lang
            ],
            "language": lang,
            "fallback_language": "DE"
          };
        axiosClient.post(`/Matching/GetTrainingMatchByPreselection`, body).then(res => {
            if (res.status === 200) {

                if (res.data && res.data.length) {
                    // let trainings = {
                    //     cvId,
                    //     jobId,
                    //     trainingList: []
                    // };
                    let trainings = [];
                    let firstTrainingId = '';

                    res.data.map((data,index) => {
                        const { title, company, target_profile_id, ranking_score, city, position } = data;
                        const training = {
                            title,
                            company,
                            target_profile_id,
                            ranking_score,
                            city,
                            position
                        }
                        // trainings.trainingList.push(training);
                        trainings.push(training);
                        if (index === 0) {
                            firstTrainingId = target_profile_id;
                        } 
                    })
                    dispatch(addTrainingList(trainings));
                    dispatch(setTrainingId(firstTrainingId));
                    const select_training = document.querySelector('#training_list');
                    select_training.value = firstTrainingId;
                    select_training.dispatchEvent(new Event('change', { bubbles: true }));
                
                    
                } 
            } else {
                dispatch(setShowLoader(false));
            }
        }).catch(err=>dispatch(setShowLoader(false)));
    }

    const selectCvHandler = (e) => {
        
        const selectedCvId = e.target.value;
        dispatch(setCvId(selectedCvId));
        dispatch(setJobId(''));
        dispatch(setJobRubricationData([]));
        dispatch(setTrainingId(''));
        dispatch(setTrainingRubricationData([]));

        if (selectedCvId) {
            getCvRubrication(selectedCvId);

        }
        
    }

    const selectJobHandler = (e) => {
        const selectedjobId = e.target.value;
        setJobTitle(e.target.selectedOptions[0].text);
        dispatch(setJobId(selectedjobId));
        getJobRubrication(selectedjobId);
        dispatch(setTrainingId(''));
        dispatch(addTrainingList([]));
        dispatch(setTrainingRubricationData([]));
        // if (selectedjobId && showTraining) {
        //     trainingMatchingHandler(cvId,selectedjobId)
        // }
        
    }
    

    const selectTrainingHandler = (e) => {
        const selectedTrainingId = e.target.value;
        dispatch(setTrainingId(selectedTrainingId));

        getTrainingRubrication(selectedTrainingId);

    }

    

    const getJobRubrication = (jobId) => {
        if (jobId) {
            dispatch(setShowLoader(true));
            axiosClient.get(`/Rubrication/GetJobRubricationById?jobId=${jobId}`).then(res => {
                if (res.status === 200) {
                    const { rubrication_items } = res.data;
                    dispatch(setJobRubricationData(rubrication_items));
                    const updatedCv = updatedCvs.find(d => d.uniqueId === cvId + jobId);
                    if (updatedCv?.uniqueId!==cvId+jobId) {
                        const sortedJobRubrications = rubrication_items.sort((a, b) => b.ranking_score - a.ranking_score);
                        const jobQualifications = resultNumber > 0 ? sortedJobRubrications.slice(0, 10) : sortedJobRubrications;
                        const missingInCv = missingItems(jobQualifications, cvRubricationData);

                        const missingInCvUpdated = missingInCv.filter(elm => elm.category_id !== 9000 && elm.category_id !== 9300);

                        const plusText = missingInCvUpdated.reduce((prev, curr) => {
                            if (prev === '') {
                                return curr.sub_category_name;
                            }
                            return prev + ', ' + curr.sub_category_name;
                        
                        }, "");
                        dispatch(setCheckedQualifications(cvId, jobId, [], cvRubricationData,plusText));
                    }
                }
                dispatch(setShowLoader(false));
            }).catch(err => dispatch(setShowLoader(false))); 
        } else {
            dispatch(setJobRubricationData([]));
            dispatch(setShowLoader(false));
        }
        
    }

    const getTrainingRubrication = (trainingId) => {
        if (trainingId) {
            dispatch(setShowLoader(true));
            axiosClient.get(`/Rubrication/GetTrainingRubricationById?trainingId=${trainingId}`).then(res => {
                if (res.status === 200) {
                    const { rubrication_items } = res.data;
                    dispatch(setTrainingRubricationData(rubrication_items));
                }
                dispatch(setShowLoader(false));
            }).catch(err=>dispatch(setShowLoader(false))); 
        } else {
            dispatch(setTrainingRubricationData([]));
        }
        
        
    }

    const handleSubmit = () => {
        if (!jobId) {
            if (jobs && jobs.jobList.length > 0) {
                dispatch(setJobId(jobs.jobList[0].target_profile_id))
                const select_job = document.querySelector('#job_list');
                select_job.value = jobs.jobList[0].target_profile_id;
                select_job.dispatchEvent(new Event('change', { bubbles: true }));

            } else {
                jobMatchingHandler(cvId);
            }
        }
    }
    const handleTrainingSubmit = () => {
        // if (!trainingId) {
        //     if (trainings && trainings.trainingList.length > 0) {
        //         dispatch(setTrainingId(trainings.trainingList[0].target_profile_id))
        //         const select_training = document.querySelector('#training_list');
        //         select_training.value = trainings.trainingList[0].target_profile_id;
        //         select_training.dispatchEvent(new Event('change', { bubbles: true }));

        //     } else {
        //         trainingMatchingHandler(cvId, jobId);
        //     }
        // }
        trainingMatchingHandler(cvId, jobId);
        
    }

    

    const selectResultNumberHandler = (e) => {
        dispatch(setResultNumber(e.target.value));
        dispatch(setShowLoader(true));
        setTimeout(() => {
            dispatch(setShowLoader(false));
        }, 500);
    }

    const search = useLocation().search;
    const cv=new URLSearchParams(search).get("cv");
    
    cvId = cvId ? cvId: cv;

    React.useEffect(() => { 
        if (cv) {
            const select_cv = document.querySelector('#cv_list');
          select_cv.dispatchEvent(new Event('change', { bubbles: true }));
        }
    }, []);

    const handleJobOption = (index, target_profile_id, title, checked) => {
        dispatch(checkJobOption(index, !checked))
        
        if (checked === false) {
            setSelectedJobs([...selectedJobs, {
                id: target_profile_id,
                title
            }]);
        } else {
            
            const updatedSelectedJobs = selectedJobs.filter(j => j.id !== target_profile_id);
            setSelectedJobs(updatedSelectedJobs);
        }
    }

    const removeSelectedJob = (id) => {
        const updatedSelectedJobs = selectedJobs.filter(j => j.id !== id);
        const index = jobOptions.findIndex(j => j.target_profile_id === id);
        dispatch(checkJobOption(index, false));
        setSelectedJobs(updatedSelectedJobs);
        
    }

    const findOptimizedJobs = () => {
        
        dispatch(setShowLoader(true));
        setShowJobLoader(true);

        const selected_job_ids = selectedJobs.map(j => j.id);
        const body = {
            selected_job_ids,
            ref_profile_id: cvId,
            actuality: -60,
            affiliation_filter: "NotFiltered",
            employment_filter: "NotFiltered",
            languages: [
                lang
            ],
            language: lang,
            fallback_language: "DE"
        };
        axiosClient.post(`/Matching/GetOptimizedJobMatch`, body).then(res => {
            if (res.status === 200) {
                if (res.data && res.data.length) {
                    let jobs = {
                        cvId,
                        jobList: []
                    };
                    let firstJobId = '';
                    res.data.map((data, index) => {
                        const { title, company, target_profile_id, ranking_score, city, position } = data;
                        const job = {
                            title,
                            company,
                            target_profile_id,
                            ranking_score,
                            city,
                            position
                        }
                        jobs.jobList.push(job);
                        if (index === 0) {
                            firstJobId = target_profile_id;
                        } 
                        
                    })
                    dispatch(addJob(jobs));
                    dispatch(setJobId(firstJobId));
                    const select_job = document.querySelector('#job_list');
                    select_job.value = firstJobId;
                    select_job.dispatchEvent(new Event('change', { bubbles: true }));
                    
                } 
            } else {
                dispatch(setShowLoader(false));
            }
            setShowJobLoader(false);
        }).catch(err => {
            dispatch(setShowLoader(false));
        });
    }

    const clearJobOptions = () => {
        setSelectedJobs([]);
        dispatch(clearCheckedJobOptions());
    }


    return (
        
        <div>
            {/* Header */}
            <div className="relative bg-lightBlue-600 md:pt-32 pb-16 pt-12">
                <div className="px-4 md:px-10 mx-auto w-full">
                    {headers.map((header, index) => <h2 key={index} className="text-3xl font-normal leading-normal mt-0 mb-2 text-blueGray-200"><i className="fa fa-circle mr-2"></i> {header}</h2>)}
                    
                </div>
                <div className="relative top-100 px-4">
                    <h4 className="text-2xl text-center font-normal leading-normal mt-0 mb-2 text-blueGray-200">{t('Upload_CV_and_find_jobs')}</h4>
                    <UploadCV />   

                    <div className=" bg-white  container  rounded mx-auto flex  flex-wrap  items-center p-4 pb-8 shadow-md flex-row ">
                        <div className="w-5/12 px-3">
                            <label htmlFor="cv_list" className="font-semibold">{t('Select_a_cv')}</label><br />
                            <select
                                id="cv_list"
                                className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full max-w-full"
                                onChange={selectCvHandler}
                                value={cvId}
                            >
                                <option value=""></option>
                                {cvList && cvList.length > 0 && cvList.map((cv, index) => <option key={index} value={cv.profile_id}>{cv.title}</option>)}
                            </select>    
                        </div>
                        <div className="w-5/12 px-3">
                            <label htmlFor="job_list" className="font-semibold flex gap-1">{t('Choose_a_job')}{ showJobLoader && <JobLoading  /> } </label>
                            <select
                                id="job_list"
                                className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full max-w-full" 
                                onChange={selectJobHandler}
                                value={jobId}
                            >
                                <option value=""></option>
                                {jobs && jobs.jobList.length > 0 && jobs.jobList.map((job, index) => <option key={index} value={job.target_profile_id}>{job.title}</option>)}
                            </select>    
                        </div>
                        
                        <div className="w-2/12 relative">
                            <button
                                className="w-full bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-3 py-3  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5"
                                onClick={handleSubmit}
                            >
                                <i className="fas fa-search"></i> {t('FIND_JOBS_Btn')}
                            </button>
                            <br/>
                            <button className="mt-2 absolute" onClick={() => setShowAdvancedSearch(!showAdvancedSearch)} >{ showAdvancedSearch ? 'hide' : 'show' } advanced search</button>
                        </div>
                        {showAdvancedSearch && <div className="px-3 w-full pt-5 flex">
                            <div className="w-6/12">
                                <label htmlFor="selected_job_list " className="font-semibold flex">
                                    Job list
                                    
                                </label>
                                <div className="flex flex-col  overflow-y-auto pt-2" style={{ maxHeight: '200px' }}>
                                    {
                                        jobOptions && jobOptions.length > 0 && jobOptions.map((job, index) => {
                                            let checked = job.checked ?? false;
                                            return (
                                                <label className="pr-4 flex justify-between" key={index} > {job.title} <input type="checkbox" checked={checked} className="ml-2" onChange={()=>handleJobOption(index,job.target_profile_id,job.title ,checked)} /></label>
                                            )
                                        })
                                    }
                                    
                                </div>
                            </div>
                            <div className="w-6/12 px-2 relative">
                                <label htmlFor="selected_job_list " className="font-semibold flex">
                                    Selected jobs
                                </label>
                                <div className="flex gap-1 flex-wrap overflow-y-auto" style={{ maxHeight: '160px' }}>
                                    {selectedJobs.length > 0 && selectedJobs.map(job=><div className="bg-lightBlue-200 py-1 px-2 rounded flex gap-2 ">{job.title} <button><i className="fas fa-times" onClick={()=>removeSelectedJob(job.id)}></i></button></div>)}
                                </div>
                                {selectedJobs.length > 0 &&
                                    <div className="flex gap-2 absolute bottom-0 right-2">
                                    <button
                                        className="bg-blueGray-200  active:bg-lightBlue-600 font-bold  text-sm px-3 py-1  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 "
                                        onClick={()=>clearJobOptions()}
                                    >
                                        clear
                                    </button>
                                    <button
                                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold  text-sm px-3 py-1  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 "
                                        onClick={findOptimizedJobs}
                                    >
                                        apply
                                    </button>
                                    </div>
                                }
                            </div>
                            
                        </div>}
                        
                    </div>         
                </div>   

                {showTraining && <div className="relative top-100 px-4 mt-10">

                    <div className=" bg-white  w-6/12  rounded mx-auto flex  flex-wrap  items-center p-4 shadow-md flex-row ">
                        <div className="w-8/12 px-3">
                            <label htmlFor="training_list">{t('Choose_a_training')}</label><br />
                            <select
                                id="training_list"
                                className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full max-w-full"
                                onChange={selectTrainingHandler}
                                value={trainingId}
                            >
                                <option value=""></option>
                                
                                {trainingList && trainingList.length > 0 && trainingList.map((training, index) => <option key={index} value={training.target_profile_id}>{training.title}</option>)}
                            </select>    
                        </div>
                        
                        
                        <button
                            className="w-4/12 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-3 py-3  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5"
                            onClick={handleTrainingSubmit}
                        >
                            <i className="fas fa-search"></i> {t('FIND_TRAINING_Btn')}
                        </button>
                    </div>         
                </div>
                }

            </div>
            <div className="py-4  px-12 2xl:px-48 flex justify-end">
                <div className="w-10/12 text-center">
                    <h1 className="text-bold text-2xl">{jobTitle}</h1>
                </div>
                <div className="w-2/12">
                    <label htmlFor="number_result_list" className="font-semibold">{t('Number_of_results')}</label><br />
                    <select
                        id="number_result_list"
                        className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full max-w-full" 
                        onChange={selectResultNumberHandler}
                        value={resultNumber}
                    >
                        <option value="10">10</option>
                        <option value="">{t('all')}</option>
                        
                    </select>    
                </div>
            </div>
        </div>
    )
}

export default SearchSection;