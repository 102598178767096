/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useEffect, useState } from "react";

import { faArrowLeft, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import MapChart from "components/Charts/MapChart";

import { Link, useLocation } from "react-router-dom";
import axiosClient from "api/axiosClient";

import Loading from 'components/Loadings/Loading'

import certificate from "../../assets/icons/certificate.png"
import dollar_coin from "../../assets/icons/dollar_coin.png"
import google_translate from "../../assets/icons/google_translate.png"
import historical from "../../assets/icons/historical.png"
import internet from "../../assets/icons/internet.png"
import location from "../../assets/icons/location.png"
import training from "../../assets/icons/training.png"
import webinar from "../../assets/icons/webinar.png"

const Training = () => {


    const [showLoader, setShowLoader] = useState(true);
    const search = useLocation().search;
    const training_id = new URLSearchParams(search).get("id");
    
    const [TrainingData, setTrainingData] = useState({});

    const trainingList = useSelector(state => state.trainingList, shallowEqual);
    const { t,i18n } = useTranslation('site');
    const lang = i18n.language.substring(0, 2);

    
    

    const getTrainingDetails = () => {
        setShowLoader(true)
        axiosClient.get(`/TrainingManagement/GetDetailTraining?trainingId=${training_id}&language=${lang}&fallbackLanguage=DE`).then((response) => {

            if (response.data ) {
                setTrainingData(response.data);
            }
            setShowLoader(false)
        })
    }   
    

    useEffect(() => {
        getTrainingDetails();
    },[search]);
    
    

    
    

    


    if (showLoader) return <Loading />;

    return (
        <>
            <div className="container mx-auto bg-white relative flex flex-col min-w-0 break-words bg-white  w-full mb-6 shadow-lg rounded mb-12 xl:mb-0 py-10  px-4 mt-10 text-black">
            <Link
                className="absolute top-5 py-2 text-lg px-5 flex gap-3 bg-red-600 text-white -left-5 flex items-center"
                to="/matching-jobs"
                >
                <FontAwesomeIcon icon={faArrowLeft}  />{t('back')}
            </Link>
                <div className="p-10 ">
                    <h3 className="font-semibold text-lg mb-2 px-5"> {t('training_title')} </h3>
                    <h1 className="font-bold text-2xl mb-2 px-5">{TrainingData?.title}</h1>
                        
                    <div className="relative p-5 mt-10" style={{background: "#F1F5F9"}}>
                        <h2 className="font-bold text-xl">{ t('key_informations') }</h2>
                        
                        <div className="flex mt-5">
                            <div className="w-1/3">
                                <ul className="flex flex-col gap-2 text-lg">
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={training} alt="training" className="w-4 h-4" />
                                            <span>
                                                {t('training_provider_name')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{TrainingData?.company?.name}</p>
                                    </li>
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={location} alt="location" className="w-4 h-4" />
                                            <span>
                                                {t('location')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{TrainingData?.city}</p>
                                    </li>
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={internet} alt="registration" className="w-4 h-4" />
                                            <span>
                                                {t('registration')}
                                            </span>
                                        </div>
                                        <a href={TrainingData?.registration_url} target="_blank" className="pl-6">{t('click_here')} <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-1/3">
                                <ul className="flex flex-col gap-2 text-lg">
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={certificate} alt="certificate" className="w-4 h-4" />
                                            <span>
                                                {t('certificate')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{TrainingData?.certificate}</p>
                                    </li>
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={google_translate} alt="training language" className="w-4 h-4" />
                                            <span>
                                                {t('training_language')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{TrainingData?.training_language}</p>
                                    </li>
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={historical} alt="time expense" className="w-4 h-4" />
                                            <span>
                                                {t('time_expense')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{TrainingData?.time_expense} { TrainingData?.time_unit }(s)</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-1/3">
                                <ul className="flex flex-col gap-2 text-lg">
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={dollar_coin} alt="cost expense" className="w-4 h-4" />
                                            <span>
                                                {t('cost_expense')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{TrainingData?.cost_expense} {TrainingData?.currency}</p>
                                    </li>
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={webinar} alt="Training type" className="w-4 h-4" />
                                            <span>
                                                {t('training_type')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{TrainingData?.training_type} </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="flex mt-20">
                        <div className="w-8/12 " >
                            <div className="w-full overflow-y-auto p-5 " style={{background: "#F1F5F9", height:"700px"}}>
                                <h2 className="text-xl font-bold text-center">{t('training_description')}</h2>
                                <p className="mt-10">
                                    {TrainingData?.body}
                                </p>
                            </div>
                            
                            
                        </div>
                        <div className="w-4/12 p-5 overflow-y-auto" style={{height: "700px"}}>
                            <h2 className="text-2xl text-center">{t('list_of_training')}</h2>
                            <div className="flex flex-col gap-2 mt-10">
                                {trainingList.length > 0 && trainingList.filter(j=>j.target_profile_id !== training_id).map(j => (
                                    <Link to={`/training-details?id=${j.target_profile_id}`} key={j.target_profile_id} className="w-full p-5" style={{ background: "#F1F5F9" }}>
                                        <h3 className="text-xl">{j?.title}</h3>
                                        <div className="flex gap-2 items-center mt-5"><i className="fas fa-building"></i> <span>{j?.company?.name}</span></div>
                                        <div className="flex gap-2 items-center mt-2"><i className="fas fa-map-marker"></i> <span>{j?.city}</span></div>
                                    </Link>
                                )) }
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-10 mt-10">
                        <div className="w-full overflow-y-auto p-5 " style={{background: "#F1F5F9", height:"300px"}}>
                            <h2 className="text-xl font-bold text-center">{t('target_group')}</h2>
                            <p className="mt-10">
                                {TrainingData?.target_group}
                            </p>
                        </div>
                        <div className="w-full overflow-y-auto p-5 " style={{background: "#F1F5F9", height:"300px"}}>
                            <h2 className="text-xl font-bold text-center">{t('requirements')}</h2>
                            <p className="mt-10">
                                {TrainingData?.requirements}
                            </p>
                        </div>
                    </div>

                    <MapChart header={t('Trainings_loaction')} data={ [TrainingData] }cssClass="w-full pr-5" shadow={false} />
                            
                            

                    
                </div>
            </div>
        </>
    )
}

export default Training;