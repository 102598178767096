import React from "react";
import { createPopper } from "@popperjs/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";


function useOutsideAlerter(ref,setDropdownPopoverShow) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownPopoverShow(false);
      } else {
        setDropdownPopoverShow(true);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const CoverLetterExamples = ({ formData, setFormData, examples }) => {
  const {t,i18n}= useTranslation('site');
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  useOutsideAlerter(popoverDropdownRef,setDropdownPopoverShow)
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  

  
  return (
    <>
      <button
        className=" block float-right border border-lightBlue-500 focus:border-lightBlue-500 active:focus:border-lightBlue-500 text-lightBlue-500 rounded px-5 "
        ref={btnDropdownRef}
        onClick={(e) => {
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex gap-2">
        {dropdownPopoverShow ? <i className="fa fa-caret-up"></i>: <i className="fa fa-caret-down"></i>}
          <span className=" text-lg font-semibold">
          Table Of Contents
          </span>
        </div>
      </button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 px-4 list-none text-left rounded shadow-lg min-w-48 "
        }
      >
        {examples.map((example,index)=><button key={index} className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 "
          } onClick={() => {
            setDropdownPopoverShow(false);
            setFormData({ ...formData, content: example });
          }}>Example {index + 1}</button>)}
        
        
        
      </div>
    </>
  );
};

export default CoverLetterExamples;
