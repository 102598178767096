import React from "react";
import Sidebar from 'components/Sidebar/Sidebar';
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import { useTranslation } from 'react-i18next';
import Form from "./Form";
import Welcome from "components/Sections/WelcomeSection";










function CvForm() {
    const { t } = useTranslation('site');
    



    return (
        <>
            <div className="relative bg-blueGray-100">
                <AdminNavbar title="create resume" />
                <div className="bg-lightBlue-600 md:pt-28 pt-12">
                    <div className="container mx-auto ">
                        <Welcome cover_letter={ true }/>
                    </div>

                </div>
                <Form/>
            </div>
            <FooterAdmin />
        </>
    );
}

export default CvForm