
const SET_CV_ID = 'set_cv_id';
const SET_JOB_ID = 'set_job_id';
const SET_JOB_TITLE = 'set_job_title';
const SET_TRAINING_ID = 'set_training_id';
const SET_RESULT_NUMBER = 'set_result_number';
const SET_SHOW_LOADER = 'set_show_loader';
const SET_CV_RUBRICATION_DATA = 'set_cv_rubrication_data';
const SET_JOB_RUBRICATION_DATA = 'set_job_rubrication_data';
const SET_TRAINING_RUBRICATION_DATA = 'set_training_rubrication_data';

export const setCvId = (cvId) => ({
    type: SET_CV_ID,
    cvId
});

export const setJobId = (jobId) => ({
    type: SET_JOB_ID,
    jobId
});

export const setJobTitle = (jobTitle) => ({
    type: SET_JOB_TITLE,
    jobTitle
});

export const setTrainingId = (trainingId) => ({
    type: SET_TRAINING_ID,
    trainingId
});

export const setResultNumber = (resultNumber) => ({
    type: SET_RESULT_NUMBER,
    resultNumber
});

export const setShowLoader = (showLoader) => ({
    type: SET_SHOW_LOADER,
    showLoader
});





export const setCvRubricationData = (cvRubricationData) => ({
    type: SET_CV_RUBRICATION_DATA,
    cvRubricationData
});

export const setJobRubricationData = (jobRubricationData) => ({
    type: SET_JOB_RUBRICATION_DATA,
    jobRubricationData
});

export const setTrainingRubricationData = (trainingRubricationData) => ({
    type: SET_TRAINING_RUBRICATION_DATA,
    trainingRubricationData
});

const initialState = {
    cvId: '',
    jobId: '',
    jobTitle: '',
    trainingId: '',
    resultNumber: 10,
    showLoader: false,
    cvRubricationData: [],
    jobRubricationData:[],
    trainingRubricationData:[]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CV_ID:
            const { cvId } = action;
            return { ...state, cvId };
        case SET_JOB_ID:
            const { jobId } = action;
            return { ...state, jobId };
        case SET_JOB_TITLE:
            const { jobTitle } = action;
            return { ...state, jobTitle };
        case SET_TRAINING_ID:
            const { trainingId } = action;
            return { ...state, trainingId };
        case SET_RESULT_NUMBER:
            const { resultNumber } = action;
            return { ...state, resultNumber };
        case SET_SHOW_LOADER:
            const { showLoader } = action;
            return { ...state, showLoader };
       
        case SET_CV_RUBRICATION_DATA:
            const { cvRubricationData } = action;
            return { ...state, cvRubricationData };
        case SET_JOB_RUBRICATION_DATA:
            const { jobRubricationData } = action;
            return { ...state, jobRubricationData };
        case SET_TRAINING_RUBRICATION_DATA:
            const { trainingRubricationData } = action;
            return { ...state, trainingRubricationData };
        default:
            return state;
    }
    
}