import React, { useState } from "react";
import HeaderSection from "components/Sections/HeaderSection";
// import LogoDynaskills from "../../assets/img/logodynaskills.png";
import { faEye, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Form() {
    const { t } = useTranslation('site');
    const [email,setEmail] = useState("");
    const [name,setName] = useState("");
    const [subject,setSubject] = useState("");
    const [message,setMessage] = useState("");
    
    const HandleSubmit = async () => {
        if(email!=="" && name!==""  && message!==""){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email, name: name, subject: subject, message: message})
            };
            fetch('https://dynaskills-mail.herokuapp.com/', requestOptions).then(function (response) {
                const data =  response.json();
                setEmail("");
                setName("");
                setSubject("");
                setMessage("");
                alert(data.data);
            }).catch(function (err) {
                console.log(err);
                setEmail("");
                setName("");
                setSubject("");
                setMessage("");
                alert("Message successfully sent!");
          });
          
        }
      };

    


    

    return (
        <>
            <HeaderSection header={t('contact_us')} subheader={t('contact_us_description')} />
            <div className="container w-1/2 mb-12 xl:mb-0 mx-auto ">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0" style={{ marginTop: "-40px" }}>
                    <Link
                        className="absolute top-5 py-2 text-lg px-5 flex gap-3 bg-red-600 text-white -left-5 flex items-center"
                        to="/matching-jobs"
                    >
                        <FontAwesomeIcon icon={faArrowLeft}  />{t('back')}
                    </Link>
                    
                    <div className="flex-auto px-4 lg:px-10 py-16 ">
                        <div className="flex flex-wrap pt-5">
                            <div className="w-full lg:w-12/12  px-4">
                                <div className="relative w-full mb-5">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    >
                                        {t('your_email_address')}
                                    </label>
                                    <input
                                        type="email"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                        
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-12/12  px-4">
                                <div className="relative w-full mb-5">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    >
                                        {t('full_name')}
                                    </label>
                                    <input
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={name}
                                        onChange={(e)=>setName(e.target.value)}
                                        
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-12/12  px-4">
                                <div className="relative w-full mb-5">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    >
                                        {t('subject')}
                                    </label>
                                    <input
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={subject}
                                        onChange={(e)=>setSubject(e.target.value)}
                                        
                                    />
                                </div>
                            </div>
 
                            <div className="w-full lg:w-12/12 px-4 ">
                                <div className="relative w-full mb-5">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    >
                                        {t('your_message_here')}
                                    </label>
                                    <textarea
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder={t('your_message_here')}
                                        rows="4"
                                        value={message}
                                        onChange={(e)=>setMessage(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                                
                            
                            
                    </div>

                    <div className="rounded-b  pb-6 ">
                        
                        <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-3 py-3  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 ml-5 text-center block mx-auto w-32" style={{margin:"0 auto"}} onClick={HandleSubmit} >{t('submit')}</button>
                    </div>
                </div>
                
            </div>
            
        </>
    );
}

export default Form;
