/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';


import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import LogoDynaskills from "../../assets/img/logodynaskills.png";

export default function Sidebar() {
  const { t,i18n } = useTranslation('site');

  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const logoutHandler = () => {
    localStorage.clear();
    window.location.href = "/auth/login";
  }
  const url = window.location.href;
  const lang = i18n.language.substring(0, 2);
  
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            <img src={LogoDynaskills} alt="logo" />
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded justify-center " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    <img src={LogoDynaskills} alt="logo" />
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            {/* <hr className="my-4 md:min-w-full" /> */}
            
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/matching-jobs") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/matching-jobs"
                >
                  <i
                    className={
                      "fas fa-business-time mr-2 text-sm " +
                      (window.location.href.indexOf("/matching-jobs") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  {t('MATCHING_JOBS_Btn')}
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/boost-chances") !== -1 || window.location.href.indexOf("/boost-chances-2") !== -1 || window.location.href.indexOf("/boost-training") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/boost-chances"
                >
                  <i
                    className={
                      "fas fa-chart-line mr-2 text-sm " +
                      (window.location.href.indexOf("/boost-chances") !== -1 || window.location.href.indexOf("/boost-chances-2") !== -1 || window.location.href.indexOf("/boost-training") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  {t('BOOST_CHANCES_Btn')}
                </Link>
              </li>

              
              
              

            </ul>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-end", position: "absolute", bottom: 0}}>
              <button
                    className={
                      "text-xs uppercase py-3 font-bold block "
                    }
                style={{ color: "#eb4444" }}
                    onClick={logoutHandler}
                  >
                <FontAwesomeIcon icon={faRightFromBracket} style={{ marginRight: "10px" }}/>
                {t('LOGOUT_Btn')}
              </button>
              <a href={`https://dynaskills-dynajobs.com/help?url=${url}&lang=${lang}`} className={
                      "text-xs uppercase py-3 font-bold block "
                    } target="_blank">
                    <FontAwesomeIcon icon={ faCircleInfo } className={"text-blueGray-300"} style={{ marginRight: "10px" }}/>
                    {t('HELP_&_GET_STARTED_Btn')}</a>
            </div>

            
          </div>
        </div>
      </nav>
    </>
  );
}
