import React, { useState } from "react";
import HeaderSection from "components/Sections/HeaderSection";
// import LogoDynaskills from "../../assets/img/logodynaskills.png";
import { faEye, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";

function CoverLetterForm() {
    const { t } = useTranslation('site');

    const Header = (
        <div className="flex flex-col items-center gap-5 font-semibold text-4xl">
            {t('create_cover_letter')}
        </div>
    );




    const examples = [
        `Dear [Mr./Ms.] [First Name] [Last Name], My name is [your name]. 

    I am thrilled to be applying for the [position] role in your company. After reviewing your job description, it’s clear that you’re looking for an enthusiastic applicant that can be relied upon to fully engage with the role and develop professionally in a self-motivated manner. 
    
    Given these requirements, I believe I am the perfect candidate for the job. I am a [insert positive trait] professional [insert your degree] who has been consistently praised as [insert positive trait] by my peers. 
    
    Over the course of my career, I have developed proven [insert 1-3 soft skills] skills, which I hope to leverage into the [position] role at your company. After reviewing my resume, I hope you will agree that I am the type of positive and driven candidate that you are looking for. 
    
    I am excited to elaborate on how my specific skills and abilities will benefit your organization. Please contact me at [PHONE] or via email at [EMAIL] to arrange for a convenient meeting time. Thank you for your consideration, and I look forward to hearing from you soon. 
    
    Sincerely,`,"",""
    ]

    const initialFormData = {
        fullname: "",
        address: "",
        phone: "",
        email: "",
        date: "",
        name_person_and_title: "",
        company: "",
        company_address: "",
        signature: "",
        signature_file: "",
        content: examples[0],
        template: "A"
    };
    
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState(initialFormData);
    const FormPages = [
        {
            title: 'Tell us about yourself',
        },
        {
            title: 'Personalize your letter',
        },
        {
            title: 'Download',
        }
    ];
    
    

    const PageDisplay = () => {
        if (page === 0) {
            return <Step1 formData={formData} setFormData={setFormData} examples={examples} />;
        } else if (page === 1) {
        return <Step2 formData={formData} setFormData={setFormData} page={page} setPage={setPage} />;
        } else {
            return <Step3 formData={formData} setFormData={setFormData} page={page} setPage={setPage} />;
        }
    };




    return (
        <>
            <HeaderSection header={Header} cssClasses="py-10"  />
            <div className="container w-full mb-12 xl:mb-0 mx-auto ">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0" style={{ marginTop: "-40px" }}>
                    
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        
                        <div className="text-center flex  justify-center  absolute " style={{left: '50%', transform: 'translate(-50%,-50%)', top: '-0.25rem'}}>
                            {FormPages.map((step, index) => (
                                
                                <button
                                    key={index}
                                    className={`w-72 font-bold py-3 border-gray-200 border-r flex justify-center gap-5 text-lg shadow-lg items-center ${index === page ? "bg-white textOrange" : "bgOrange text-white  "}`}
                                    onClick={()=>setPage(index)}
                                >
                                    <div className={`circle text-2xl ${index === page ? "bgOrange text-white  " : "bg-white textOrange  "}`}>{ index +1 }</div>
                                    {step.title}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="bg-white px-10">
                        
                        {PageDisplay()}
                    </div>
                    
                    <div className=" bg-white flex justify-center py-10">
                        <button className="borderOrange rounded-full border-2 textOrange text-lg uppercase font-bold py-2 px-5" onClick={()=>setPage(page+1)} >{t('continue')}</button>
                    </div>
                </div>
                
            </div>
            
        </>
    );
}

export default CoverLetterForm;
