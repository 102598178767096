

const ADD_TRAINING_LIST = 'add_training_list';



export const addTrainingList = (training_list) => ({
    type: ADD_TRAINING_LIST,
    training_list
});
const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_TRAINING_LIST:
            const { training_list } = action;
            // const newState = [...state, training_list];
            // console.log(newState);
            return training_list;
        default:
        return state;
            
    }
}