import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './i18n';


import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "index.css"

// layouts

import Auth from "layouts/Auth.js";
import MatchingJobs from "layouts/MatchingJobs.js";
import BoostChances from "layouts/BoostChances.js";
// import BoostChances2 from "layouts/BoostChances2.js";
import BoostByTraining from "layouts/BoostByTraining.js";
import BoostCareer from "layouts/BoostCareer.js";
import JobDetails from "layouts/JobDetails.js";
import TrainingDetails from "layouts/TrainingDetails.js";
import Contact from "layouts/Contact.js";




// views without layouts

import Profile from "views/Profile.js";
import { Provider } from "react-redux";

import store from './redux/store';
import CvForm from "layouts/cv/CvForm";
import Base64 from "views/Base64";
import CoverLetter from "layouts/coverLetter/CoverLetter";
import CvHealthRestrictions from "layouts/restrictions/CvHealthRestrictions";
import JobHealthRestrictions from "layouts/restrictions/JobHealthRestrictions";




ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback="loading">
    <BrowserRouter>
      <Switch>
        {/* add routes with layouts */}
        <Route path="/auth" component={Auth} />
        {/* add routes without layouts */}
        <Route path="/profile" exact component={Profile} />
        <Route path="/matching-jobs" exact component={MatchingJobs} />
        <Route path="/boost-chances" exact component={BoostChances} />
        {/* <Route path="/boost-chances-2" exact component={BoostChances2} /> */}
        <Route path="/boost-training" exact component={BoostByTraining} />
          <Route path="/boost-career" exact component={BoostCareer} />
          <Route path="/cv" exact component={CvForm} />
          {/* add redirect for first page */}
          <Route path="/base64" exact component={Base64} />
          <Route path="/job-details" exact component={JobDetails} />
          <Route path="/training-details" exact component={TrainingDetails} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/cover-letter" exact component={CoverLetter} />
          <Route path="/cv-health-restrictions" exact component={CvHealthRestrictions} />
          <Route path="/job-health-restrictions" exact component={JobHealthRestrictions} />

        <Redirect from="*" to="/auth" />
      </Switch>
      </BrowserRouter>
      </Suspense>
    </Provider>
    ,
  document.getElementById("root")
);
