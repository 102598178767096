import React from "react";
import { Link, useLocation } from "react-router-dom";
import UploadCV from "components/Modals/UploadCV";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { addJob } from '../../redux/reducer/jobListReducer';
import { addJobOptions, checkJobOption, clearCheckedJobOptions } from '../../redux/reducer/jobOptionsReducer';
import { addTrainingList } from '../../redux/reducer/trainingListReducer';
import {
    setCvId,
    setJobId,
    setTrainingId,
    setResultNumber,
    setShowLoader, 
    setCvRubricationData, 
    setJobRubricationData,
    setTrainingRubricationData
} from '../../redux/reducer/searchReducer';
import axiosClient from "api/axiosClient";
import missingItems from "utils/missingItems";
import { setCheckedQualifications } from "redux/reducer/updatedCvsReducer";
import { useTranslation } from "react-i18next";
import JobLoading from '../Loadings/jobLoading'
import SelectCV from '../../components/Sections/SearchSections/SelectCV'
import SelectJob from '../../components/Sections/SearchSections/SelectJob'
import OptimizeCv from '../../components/Sections/SearchSections/OptimizeCv'
import SelectTraining from '../../components/Sections/SearchSections/SelectTraining';
import AddCv from '../../components/Sections/SearchSections/AddCv';
import MatchingJobsCVSection from '../../components/Sections/MatchingJobsCVSection'
import BoostChancesSection from "./BoostChancesSection";
import BoostByTrainingSection from "./BoostByTrainingSection";
import Loading from 'components/Loadings/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Welcome from "./WelcomeSection";
import { setSection } from "redux/reducer/sectionReducer";
import Info from "components/Modals/Info";


function Search({ headers = [], showTraining = false, showJobApply, setShowJobApply }) {
    const { t } = useTranslation('site');
    //const [section, setSection] = React.useState(0);
    const dispatch = useDispatch();
    const section = useSelector(state => state.section);
    let {
        cvId,
        jobId,
        jobTitle,
        resultNumber, 
        showLoader
    } = useSelector(state => state.searchInput, shallowEqual);
    const addCv = useSelector(state => state.addCv)
    const formSections = [
        {
            'title': addCv ? t('add_cv') : t('set_cv'),
            'icon':  addCv ? <i className="fa fa-file-upload " ></i> : <i className="fa fa-file-alt"></i>,
            'disabled': false
        },
        {
            'title': t('select_job'),
            'icon': <i className="fa fa-briefcase"></i>,
            'disabled': cvId? false: true
        },
        {
            'title': t('optimise_cv'),
            'icon': <i className="fas fa-rocket"></i>,
            'disabled': jobId? false: true
        },
        {
            'title': t('select_training'),
            'icon': <i className="fas fa-graduation-cap"></i>,
            'disabled': jobId? false: true
        },
    ];

    const SectionDisplay = () => {
        let displayedSection;
        switch (section) {
            case 0 :
                displayedSection = addCv ? <AddCv /> : <SelectCV />;
                break;
            case 1 :
                displayedSection = <SelectJob />;
                break;
            case 2 :
                displayedSection = <OptimizeCv />;
                break;
            default:
                displayedSection = <SelectTraining />;
            
        }
        return displayedSection;
    };

    const nextBtnDisabled = () => {
        if ((section === 0 && cvId) || (section > 0 && jobId)) {
            return false;
        } 
        
        return true;
    }

    const diplayPage = () => {
        if (section === 0 || section === 1) {
            return <MatchingJobsCVSection />
        } else if (section === 2 ) {
            return <BoostChancesSection />;
        } else {
            return <BoostByTrainingSection />;
        }
    }

    const selectResultNumberHandler = (e) => {
        dispatch(setResultNumber(e.target.value));
        dispatch(setShowLoader(true));
        setTimeout(() => {
            dispatch(setShowLoader(false));
        }, 500);
    }
    
    return (
        
        <div>
            {/* Header */}
            <div className="relative bg-lightBlue-600 md:pt-28 pb-16 pt-12 ">
                
                
                <div className="container mx-auto">
                    <Welcome />
                    <div className="relative ">
                        {/* <h4 className="text-2xl text-center font-normal leading-normal mt-0 mb-2 text-blueGray-200">{t('Upload_CV_and_find_jobs')}</h4>
                        <UploadCV />    */}
                        <div className="     rounded mx-auto flex  flex-wrap   shadow-md flex-col ">
                            <div className="relative flex">
                                {/* <div className="absolute bg-white h-2 ml-10" style={{width: "600px", marginTop: "-40px"}}></div> */}
                                {formSections.map((step, index) => {
                                    const { disabled, title, icon } = step;
                                    let classes = "py-4 px-10 font-bold flex items-center gap-5";
                                    if (index === 0) {
                                        classes += " rounded-tl-lg";
                                    } 
                                    if (disabled) classes += " cursor-not-allowed";
                                    classes += index === section ? ` bg-white text-lightBlue-600` : ` bg-lightBlue-600 text-white `;
                                    return (
                                        <div className="relative flex justify-center" key={index}>
                                            <button
                                                key={index}
                                                className={classes}
                                                onClick={() => dispatch(setSection(index))}
                                                disabled={disabled}
                                                > {icon}{title}
                                            </button>
                                            <div className={`absolute circle_${index}`}  style={{ marginTop: "-70px" }}>
                                                <div className={`bg-white font-bold rounded-full w-12 h-12 text-lg flex items-center justify-center mx-auto ${index===section? 'text-lightBlue-500': 'text-gray-500'}`} >{index + 1}</div>
                                                {index < 3 && <div className="h-2 bg-white" style={{width: "245px",position: "absolute",left: "46px",top: "20px"}}></div>}
                                            </div>
                                        </div>
                                )})}
                                
                            </div>
                            <div className="bg-white p-8  ">
                                {SectionDisplay()}
                                
                            </div>
                            <div className="rounded-b bg-white mb-0 px-6 py-6 ">
                                    {section > 0 && <button className="wizard_btn back_btn" onClick={()=>dispatch(setSection(section-1))} ><FontAwesomeIcon icon={faArrowLeft} /> {t('back')}</button>}
                                    {(section <3 && !addCv) && <button className={`wizard_btn next_btn ${nextBtnDisabled() ? `cursor-not-allowed`: ''}`} disabled={nextBtnDisabled()} onClick={()=>dispatch(setSection(section+1))} >{t('next')} <FontAwesomeIcon icon={faArrowRight} /></button>
                                }
                                </div>
                        </div>
                        
                    </div>  
                </div> 

                

            </div>
            <div className="container mx-auto flex justify-between pt-10">
                <div className="text-center">
                   
                    {jobTitle && <h1 className="text-bold text-2xl">
                        {jobTitle}
                        <Link
                            to={`/job-details?id=${jobId}`}
                            onClick={(e)=>setShowJobApply(!showJobApply)}
                            className=" bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-3 py-3  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5 ml-5"
                        >
                            {t('show_details')}
                        </Link>
                    </h1>}
                </div>
                {cvId && <div className="relative">
                    <Info cssClasses="absolute -right-10 top-8" msg="number_of_results_info" />
                    <label htmlFor="number_result_list" className="font-semibold">{t('Number_of_results')}</label><br />
                    <select
                        id="number_result_list"
                        className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full max-w-full" 
                        onChange={selectResultNumberHandler}
                        value={resultNumber}
                    >
                        <option value="10">10</option>
                        <option value="">{t('all')}</option>
                        
                    </select>    
                </div>}
            </div>
            {showLoader ? <Loading/> : diplayPage()}
            
        </div>
    )
}

export default Search;