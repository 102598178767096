const SET_CHECKED_QUALIFICATIONS = 'set_checked_qualifications';
const UPDATE_CV_RUBRICATIONS = 'update_cv_rubrications';


export const setCheckedQualifications = (cvId, jobId, checkedQualifications, cvRubrications,plusText) => ({
    type: SET_CHECKED_QUALIFICATIONS,
    cvId,
    jobId,
    checkedQualifications,
    cvRubrications,
    plusText
});

export const updateCvRubrications = (cvId, jobId, updatedQualifications) => ({
    type: UPDATE_CV_RUBRICATIONS,
    cvId,
    jobId,
    updatedQualifications,
});




const initialState = [];


export default function updatedCvsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CHECKED_QUALIFICATIONS:
            const {
                cvId,
                jobId,
                checkedQualifications,
                cvRubrications,
                plusText
            } = action;
            const uniqueId = cvId+jobId
            const newState = state.filter(d => d.uniqueId !== uniqueId);
            
            const item = {
                uniqueId,
                cvId,
                jobId,
                checkedQualifications,
                cvRubrications,
                plusText
            };

            return [...newState, item];
        case UPDATE_CV_RUBRICATIONS:
            const {
                cvId: idCv,
                jobId: idJob,
                updatedQualifications,
            } = action;
            const idUnique = idCv+idJob
            const element = state.find(d => d.uniqueId === idUnique);
            if(element) {
                const updatedItem = {
                    ...element,
                    cvRubrications : updatedQualifications,
                };
                const updatedState = state.filter(d => d.uniqueId !== idUnique);
                return [...updatedState, updatedItem];
            }

            return state;
            
            
        default:
            return state;
    }
}