import { useState } from "react";
import { useTranslation } from "react-i18next";

const Info = ({cssClasses="", msg="", showImage = false, prefix=""}) => {
    const { t, i18n } = useTranslation('site');
    let lang = i18n.language.substring(0, 2);
    if (lang ==="zh") {
        lang="en";
    }
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <div className={cssClasses}>
                <button
                    
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                    <i className="fa fa-info-circle fa-xl" ></i>
                </button>
            </div>
            {showModal && (
        
                <div style={{zIndex: 9999}}>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        
                        
                    >
                        <div className="relative w-auto my-6 mx-auto w-1/3 top-24" >
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="  flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-2xl font-semibold">
                                {t('Informations')}
                            </h3>
                            <button
                                className=" ml-auto border-0  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                            >
                                <span className="h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                                </span>
                            </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                                {showImage ? 
                                (<img className="max-h-screen" src={require(`assets/pdfImages/${lang}_${prefix}restrictions.jpg`).default} alt="info" />)
                                : (<div className=" py-2" style={{whiteSpace: "pre-line"}}>
                                    {t(msg)}
                                </div>)}
                                
                                
                                
                            </div>
                            
                        </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
            )}
        </>
    )
}

export default Info;