

const SHOW_ADD_CV = 'show_add_cv';



export const showAddCv = (bool) => ({
    type: SHOW_ADD_CV,
    bool
});




const initialState = JSON.parse(localStorage.getItem('show_add_cv')) || false;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_ADD_CV:
            const { bool } = action
            return bool;
        default:
            return state;
            
    }
}