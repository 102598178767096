import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import A from "components/Pdf/CoverLetters/A";
import B from "components/Pdf/CoverLetters/B";
import C from "components/Pdf/CoverLetters/C";
import { useTranslation } from "react-i18next";





const Step3 = ({ formData, setFormData, page, setPage }) => {
    
    const { t } = useTranslation('site');

    const template = formData.template;

    let document ;
    if (template === "A") {
        document = <A formData={formData} />;
    } else if (template === "B") {
        document = <B formData={formData} />;
    } else {
        document = <C formData={formData} />;
    }
    
    const DownloadBtn =() => <><i className="fa fa-download"></i> {t('download')}</>;

    return (
        <div className="pt-10">
            <button onClick={() => setPage(0)} className="uppercase text-lg flex gap-2 font-semibold items-center">
                <i className="fa fa-angle-left " ></i>
                Edit your personal information
            </button>
            <div className="py-5 flex flex-col gap-5">
                <h1 className="text-2xl font-bold uppercase text-center">Congratulations!</h1>
                <h1 className="text-2xl font-bold uppercase text-center">Your cover letter is ready!</h1>
            </div>
            <div className="w-full lg:w-12/12  mb-5">
                <div className="relative w-full mb-5 flex justify-between">
                    <PDFDownloadLink className=" rounded-full border-2 bgOrange text-white text-lg uppercase font-bold py-2 px-10 flex gap-5 justify-center items-center w-fit " document={document} fileName="my-cover-letter.pdf">
                        {
                        ({ blob, url, loading, error }) => {
                            return loading ? t('loading_document') : <DownloadBtn/>;
                        }
            
        
                        }
                    </PDFDownloadLink>
                    <button className="borderOrange rounded-full border-2 textOrange text-lg uppercase font-bold py-2 px-5" onClick={()=>setPage(0)} >{t('edit')}</button>
                </div>
            </div>
            <div className="w-full">
                <PDFViewer  style={{width:  "100%", height:  1000}}>
                    {document}
                </PDFViewer>
            </div>
        </div>
    )
}

export default Step3;