import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import ForgetPassword from "views/auth/ForgetPassword";
import ResetPassword from "views/auth/ResetPassword";

export default function Auth() {
  return (
    <>
      <main>
        <section className="relative w-full h-full py-20 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-200 bg-no-repeat bg-full"
            
          ></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/forget-password" exact component={ForgetPassword} />
            <Route path="/auth/reset-password" exact component={ResetPassword} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
