

const ADD_JOB_OPTIONS = 'add_job_options';
const CHECK_JOB_OPTION = 'check_job_option';
const CLEAR_CHECKED_JOB_OPTIONS = 'clear_checked_job_options';


export const addJobOptions = (job_list) => ({
    type: ADD_JOB_OPTIONS,
    job_list
});

export const checkJobOption = (index, checked) => ({
    type: CHECK_JOB_OPTION,
    index,
    checked
});

export const clearCheckedJobOptions = () => ({
    type: CLEAR_CHECKED_JOB_OPTIONS
});

const initialState = [];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_JOB_OPTIONS:
            
            const { job_list } = action;
            const newState = [...job_list];
            return newState;
        case CHECK_JOB_OPTION:
            const { index, checked } = action;
            let updatedState = [...state];
            updatedState[index] = {...updatedState[index], checked};

            return updatedState;
        case CLEAR_CHECKED_JOB_OPTIONS:
            const unchekedJobsState = state.map(j => ({
                    ...j,
                    checked:false
                }))
            return unchekedJobsState;
        default:
            return state;
            
    }
}