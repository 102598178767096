import axiosClientNode from "api/axiosClientNode";

const upload = (file, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);

  return axiosClientNode.post("/cvs", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};



const addCvToUser = (owner_contact_id, profile_id, cvTitle, filename) => {
  
  let data = { owner_contact_id, profile_id, cvTitle, filename }
  

  return axiosClientNode.post("/cvs/addcv", data);
};

const remove = (filename) => {
  
  return axiosClientNode.delete("/cvs/"+filename);
};



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  upload,
  addCvToUser,
  remove
};