import React from "react";

// components

import CardStats from "components/Cards/CardStats.js";
import { useTranslation } from "react-i18next";
import Info from "components/Modals/Info";

export default function HeaderStats({ data }) {
  const {t}= useTranslation('site');
  return (
    <>
      {/* Header */}
      <div className="relative  md:pt-32 pb-32 pt-12 mx-auto">
        
        <div className="px-4 md:px-10 mx-auto w-full">
        
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle=""
                  statTitle={data[0]}
                  statArrow=""
                  statPercent=""
                  statPercentColor="text-emerald-500"
                  statDescripiron={ t('cv_job_mapping') }
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle=""
                  statTitle={data[1]}
                  statArrow=""
                  statPercent=""
                  statPercentColor="text-red-500"
                  statDescripiron={t('Completed_CV_mapping')}
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4 relative">
              <Info cssClasses="absolute -right-5 top-5" msg="header_stats_info" />
                <CardStats
                  statSubtitle=""
                  statTitle={data[2]}
                  statArrow=""
                  statPercent=""
                  statPercentColor="text-orange-500"
                  statDescripiron={t('Boosted_by_training')}
                  statIconName="fas fa-rocket"
                  statIconColor="bg-pink-500"
                />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
