import React from "react";
import { v4 as uuid } from 'uuid';

const SignatureImg = ({formData, setFormData, imgSrc}) => {

    const file = React.useRef(null);
    const label = React.useRef(null);
    const labelText = React.useRef(null);
    const labelRemove = React.useRef(null);


    

    
    const getBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let result = this.result;
            setFormData({ ...formData, signature_file: result });
        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
      }

    const fileHandler = (e) => {

        const fileName = String.raw`${e.target.value}`.split('\\').pop();
        if (fileName) {
            labelText.current.innerHTML = fileName;
            getBase64(e.target.files[0])
        } else {
            labelText.current.innerHTML = '';
        }
    }

    const removeFile = () => {
        setFormData({ ...formData, signature_file: "" });

        file.current.value = "";
        labelText.current.innerHTML = '';
    }

    const idInput = uuid();
    return (
        <div className="w-32">
            <input type="file" id={idInput} ref={file} onChange={e => fileHandler(e)} style={{ display: 'none' }} />
            <label htmlFor={idInput} className="bg-blueGray-300 flex items-center py-2 px-5 gap-5 cursor-pointer" ref={label}>
                <span>Upload</span>
                <i className="fa fa-paperclip "></i>

            </label>
            <div className="text-center  flex items-center">
                <span ref={labelText}></span>
                <i className="fa fa-trash remove cursor-pointer ml-2" ref={labelRemove} onClick={removeFile} style={{ display: 'none' }}></i>
            </div>
        </div>
    )
}
export default SignatureImg;