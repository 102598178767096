import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Info from 'components/Modals/Info';


  
function BarChart({ data, header, subheader, height = 200, legend = false, stacked = false, cssClass = "w-full", showButtons = false, info="", restrictionButtonUrl="", restrictionTitle }) {
  const { t } = useTranslation('site');
  
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );

      const options = {
        indexAxis: 'y' ,
        elements: {
          bar: {
            borderWidth: 1,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            position: 'top' ,
            display: legend
          },
          title: {
            display: false,
            text: '',
          },
        },
        scales: stacked && {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
      }
      };


    const BarData = {
        labels: data[0].data.map((d) => {
            return d.restriction.split(' - ')[1]
        }),
        datasets: data.map((d) => {
            return {
                label: d.label,
                data: d.data.map((elm) => elm.value),
                backgroundColor: d.backgroundColor,
            };
        }),
    }
  return (
    
    <div className={cssClass}>
      {restrictionButtonUrl && <div className='pb-4 flex gap-2 items-center'>
        <Link to={restrictionButtonUrl} className="bg-pink-400 p-2 rounded text-white text-lg cursor-pointer flex gap-2 items-center">
        <i className="fa fa-hand-holding-medical"></i>
          {restrictionTitle}
        </Link>
        <Info cssClasses="" msg={t('CV/Job Health restrictions info')} />
      </div>}
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
              {subheader}
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
              {header} 
              </h2>
              {info && <Info cssClasses="absolute right-0 top-2" msg={info} />}
            </div>
            {showButtons && <div className="flex ">
              <div className="flex flex-col px-2">
                  <Link className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" to="matching-jobs">{t('cv_job_mapping')}</Link>
              </div>
              <div className="flex flex-col px-2">
                <Link className="bg-lightBlue-500 text-white text-center active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" to="/boost-training">{t('BOOST_BY_TRAINING_Btn')}</Link>
              </div>
              <div className="flex flex-col px-2">
                <Link className="bg-lightBlue-500 text-white text-center active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" to="/boost-career"> { t('BOOST_CAREER_Btn')}</Link>
              </div>
            </div>}
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative">
            <Bar height={height} options={options} data={BarData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarChart;