import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid';
import SocialLink from "./SocialLink";
import { useTranslation } from "react-i18next";

function Step0({ formData, setFormData }) {
    const { t } = useTranslation('site');
    const addNewSocialLink = () => {
        const oldSocialLinks = formData.socialLinks;
        const newSolcialLink = {
            id: uuid(),
            name: '',
            link: '', 
            createdAt: Date.now(),
        };
        const updatedSocialLinks = [...oldSocialLinks, newSolcialLink];
        setFormData({...formData, socialLinks: updatedSocialLinks});
    }
return (

    <div className="flex-auto px-4 lg:px-10 py-10 ">
        <div className="flex flex-wrap pt-5">
        <div className="w-full lg:w-12/12  px-4">
                <div className="relative w-full mb-5">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('cv_title')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={formData.cvTitle}
                        onChange={(e) =>
                            setFormData({ ...formData, cvTitle: e.target.value })
                        }
                        
                    />
                </div>
            </div>
                <div className="w-full lg:w-6/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                            {t('firstname')}
                        </label>
                        <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={formData.firstname}
                            onChange={(e) =>
                                setFormData({ ...formData, firstname: e.target.value })
                            }
                            
                        />
                    </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                            {t('lastname')}
                        </label>
                        <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={formData.lastname}
                            onChange={(e) =>
                                setFormData({ ...formData, lastname: e.target.value })
                            }
                            
                        />
                    </div>
                </div>
                <div className="w-full lg:w-12/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                            {t('actual_job')}
                        </label>
                        <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={formData.actualJob}
                            onChange={(e) =>
                                setFormData({ ...formData, actualJob: e.target.value })
                            }
                        />
                    </div>
                </div>
                
                <div className="w-full lg:w-4/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                            {t('postal_code')}
                        </label>
                        <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={formData.postalCode}
                            onChange={(e) =>
                                setFormData({ ...formData, postalCode: e.target.value })
                            }
                                
                        />
                    </div>
                </div>

                <div className="w-full lg:w-4/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                            {t('city')}
                        </label>
                        <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={formData.city}
                            onChange={(e) =>
                                setFormData({ ...formData, city: e.target.value })
                            }
                                
                        />
                    </div>
                </div>

                <div className="w-full lg:w-4/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            {t('country')}
                        </label>
                        <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={formData.country}
                            onChange={(e) =>
                                setFormData({ ...formData, country: e.target.value })
                            }
                                
                        />
                    </div>
                </div>

                <div className="w-full lg:w-6/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                            {t('phone')}
                        </label>
                        <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={formData.phone}
                            onChange={(e) =>
                                setFormData({ ...formData, phone: e.target.value })
                            }
                            
                        />
                    </div>
                </div>
                <div className="w-full lg:w-6/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                            {t('email')}
                        </label>
                        <input
                            type="email"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={formData.email}
                            onChange={(e) =>
                                setFormData({ ...formData, email: e.target.value })
                            }
                            
                        />
                    </div>
                </div>

                
                
            </div>
            <div>
                <h6 className="text-blueGray-400 text-sm ml-3 mt-3 mb-6 font-bold uppercase">
                    {t('add_social_links')}
                </h6>
                {formData.socialLinks.map(item=><SocialLink key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
                
                <button type="button" className="text-sm ml-3" onClick={() =>addNewSocialLink()} >
                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                    
                </button>
            </div>
    </div>
      
);
}

export default Step0;
