import ReactPDF, {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    Font,
  } from "@react-pdf/renderer";
  import { useTranslation } from "react-i18next";
  
  const styles = StyleSheet.create({
    body: {
        fontFamily: 'Oswald',
        paddingBottom: 10,
        fontSize: "14px"
    }
    
  
  });
  
  // const formData = JSON.parse(localStorage.getItem('generatedCv'));
  // console.log(formData);
  
  
  
const C = ({ formData }) => {
    const { t } = useTranslation('site');
    return (
        <Document>
            <Page style={styles.body} size="A4"  >
                <View >
                    <View style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', marginBottom: '40px', backgroundColor: "#0ea5e9", color: "#fff", padding: '20px 10px' }}>
                        <Text style={{marginBottom: '12px', fontSize: '22px'}} >{formData.fullname}</Text>
                        <Text>{formData.address}</Text>
                        <Text>{formData.phone}</Text>
                        <Text>{formData.email}</Text> 
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: '20px 10px'}}>
                        <View >
                            <Text>{formData.name_person_and_title}</Text>
                            <Text>{formData.company}</Text>
                            <Text>{formData.company_address}</Text>
                        </View>
                        <Text>{formData.date}</Text>

                    </View>
                    <Text style={{whiteSpace: "pre-line", padding: '10px 10px'}}>{formData.content}</Text>
                    <Text style={{ padding: '10px 10px' }}>{formData.fullname}</Text> 
                    {formData.signature_file !== "" && <Image src={ formData.signature_file } style={{ width: '30%', padding: '20px 10px'}} />}
                </View>
            
            </Page>
        </Document>
    );
}
  
  Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });
  
  
  
  export default C;
  
  
  
  
  
  