import { useState} from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Diploma = ({ id, title = "", university = "",startDate="",endDate="", isChecked=false, createdAt, formData, setFormData }) => {
    const { t } = useTranslation('site');

    const [titleState, setTitleState] = useState(title);
    const [universityState, setUniversityState] = useState(university);
    const [startDateState, setStartDateState] = useState(startDate);
    const [endDateState, setEndDateState] = useState(endDate);
    const [isCheckedState, setIsCheckedState] = useState(isChecked);

    const diplomaObjectState = {
        id,
        createdAt,
        title: titleState,
        university: universityState,
        startDate: startDateState,
        endDate: endDateState,
        isChecked: isCheckedState
    };


    const updateTitle = (e) => {
        setTitleState(e.target.value);
        const oldDiplomas = formData.diplomas.filter(el => el.id !== id);
        const newDiploma = { ...diplomaObjectState, title: e.target.value };
        const updatedDiplomas = [...oldDiplomas, newDiploma].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, diplomas: updatedDiplomas });
    }

    const updateUniversity = (e) => {
        setUniversityState(e.target.value);
        const oldDiplomas = formData.diplomas.filter(el => el.id !== id);
        const newDiploma = { ...diplomaObjectState, university: e.target.value };
        const updatedDiplomas = [...oldDiplomas, newDiploma].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, diplomas: updatedDiplomas });
    }
    const updateStartDate = (e) => {
        setStartDateState(e.target.value);
        const oldDiplomas = formData.diplomas.filter(el => el.id !== id);
        const newDiploma = { ...diplomaObjectState, startDate: e.target.value };
        const updatedDiplomas = [...oldDiplomas, newDiploma].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, diplomas: updatedDiplomas });
    }
    const updateEndDate = (e) => {
        setEndDateState(e.target.value);
        const oldDiplomas = formData.diplomas.filter(el => el.id !== id);
        const newDiploma = { ...diplomaObjectState, endDate: e.target.value };
        const updatedDiplomas = [...oldDiplomas, newDiploma].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, diplomas: updatedDiplomas });
    }
    const updateIsChecked = (e) => {
        setIsCheckedState(!isCheckedState);
        const oldDiplomas = formData.diplomas.filter(el => el.id !== id);
        const newDiploma = { ...diplomaObjectState, isChecked: !isCheckedState };
        const updatedDiplomas = [...oldDiplomas, newDiploma].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, diplomas: updatedDiplomas });
    }
    

    const removeDiploma = () => {
        const oldDiplomas = formData.diplomas.filter(el => el.id !== id);
        const updatedDiplomas = [...oldDiplomas].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, diplomas: updatedDiplomas });
    }

    return (
        <div className="flex flex-col gap-8  pb-8 relative">
            <div className="flex items-end pb-8 relative">
                <div className="w-3/12">
                    {t('diplome')}*
                </div>
                <div className="w-9/12">
                    <input
                        type="text"
                        className="w-full rounded h-10 bg-blueGray-200 border-0" 
                        value={titleState}
                        onChange={(e) => updateTitle(e)}
                    />
                    
                </div>
                <div className="w-9/12 absolute right-0 bottom-0">
                    <label>
                        <input
                            type="checkbox"
                            className="mr-2" 
                            value={isCheckedState}
                            onChange={(e) => updateIsChecked(e)}
                        />
                        {t('diplome_for_this_job')}
                    </label>
                </div>
            </div>
            <div className="flex items-end">
                <div className="w-3/12">
                    {t('university')}*
                </div>
                <div className="w-9/12">
                    <input
                        type="text"
                        className="w-full rounded h-10 bg-blueGray-200 border-0" 
                        value={universityState}
                        onChange={(e) => updateUniversity(e)}
                    />
                </div>
            </div>
            <div className="flex items-end">
                <div className="w-3/12">
                    {t('year')}*
                </div>
                <div className="w-3/12 flex items-end">
                    <div className="w-3/12">{t('start')}</div>
                    <div className="w-9/12">
                        <input
                            type="date"
                            className="w-full rounded h-10 bg-blueGray-200 border-0" 
                            value={startDateState}
                            onChange={(e) => updateStartDate(e)}
                        />
                    </div>
                </div>
                <div className="w-3/12"></div>
                <div className="w-3/12 flex items-end">
                    <div className="w-3/12">{t('end')}</div>
                    <div className="w-9/12">
                        <input
                            type="date"
                            className="w-full rounded h-10 bg-blueGray-200 border-0" 
                            value={endDateState}
                            onChange={(e) => updateEndDate(e)}
                        />
                    </div>
                </div>
            </div>

            
            <div className="absolute -right-7 top-2">
                {formData.diplomas.length === 1 ? (
                    <button onClick={() => removeDiploma()} disabled="disabled" style={{ cursor: 'not-allowed' }} >
                        <FontAwesomeIcon icon={faTrash} style={{ color: "gray" }} />
                    </button>
                ): (
                    <button onClick={() => removeDiploma()} >
                        <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                    </button>
                )}
            </div>
        </div>
    )

}


export default Diploma;