import { useState } from "react";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showAlert } from "redux/reducer/alertReducer";
import { addCvList } from "redux/reducer/cvListReducer";
import { addCv } from "redux/reducer/cvListReducer";
import { addJob } from "redux/reducer/jobListReducer";
import { addJobOptions } from "redux/reducer/jobOptionsReducer";
import { setJobRubricationData } from "redux/reducer/searchReducer";
import { setJobTitle } from "redux/reducer/searchReducer";
import { setJobId } from "redux/reducer/searchReducer";
import { setCvId } from "redux/reducer/searchReducer";
import { v4 as uuid } from 'uuid';
import aciosClient from "../../api/axiosClient"

export default function Modal() {
  const { t } = useTranslation('site');
  const [showModal, setShowModal] = useState(false);

  const cvList = useSelector(state =>state.cvList);
  const isPremium = useSelector(state => state.isPremium);

  const [cvFile, setCvFile] = useState(null);
  const [cvTitle, setCvTitle] = useState('');

  const dispatch = useDispatch();

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let result = this.result;
      const fileData=result.replace('data:application/pdf;base64,', '');
      setCvFile(fileData);
    }
    reader.onerror = function (error) {
        console.log('Error: ', error);
    }
  }

  const fileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCvTitle(file.name)
      getBase64(file);
    }
  }
    

  const uploadCvHandler = (e) => {
    e.preventDefault();

    const customer_id = uuid();
    const profile_id = uuid();
    
    const data = {
      customer_id,
      profile_id,
      title: cvTitle,
      raw_data: cvFile,
      file_type: "Pdf"
    };
     aciosClient.post('/CVManagement/UploadCVFromFile', data).then(res => {
      const { profile_id, owner_contact_id } = res.data;
      const cv = {
        profile_id,
        customer_id,
        owner_contact_id,
        title: cvTitle
      };
      getCvList();
      dispatch(addCv(cv));
      dispatch(setCvId(profile_id));
      dispatch(setJobId(''));
      dispatch(setJobTitle(''));
      dispatch(addJob([]));
      dispatch(addJobOptions([]));
      dispatch(setJobRubricationData([]));
      const select_cv = document.querySelector('#cv_list');
      select_cv.dispatchEvent(new Event('change', { bubbles: true }));
      setShowModal(false);
      setCvTitle('');
    }).catch(error => {
      console.log(error);
      setShowModal(false);
    })

    async function getCvList() {
      let cvListUpdated = [];
    await aciosClient.get('/CVManagement/GetUploadedCVs').then(function (response) {
        if (response.data && response.data.length > 0) {
          cvListUpdated = response.data.map(data => {
            return {
              profile_id: data.cv_id,
              title: data.details.title
            }
          })
      }
      console.log(cvListUpdated.length)
      if (3 - cvListUpdated.length === 0) {
        dispatch(showAlert(true));
      }
      dispatch(addCvList(cvListUpdated))
      });
    
  }
    
    // axios.post('https://dynaskills-webservice.cloudapp.net/api/CVManagement/UploadCVFromFile', data, {
    //   headers: {
    //     'Authorization': 'DYNAService ZHluYXNraWxsc0NVRzoxRHluYXNrNENIIQ=='
    //   }
    // }).then(res => {
    //   if (res.status === 200) {
    //     const { profile_id, owner_contact_id } = res.data;
    //     const cv = {
    //       profile_id,
    //       customer_id,
    //       owner_contact_id,
    //       title: cvTitle
    //     };
        
    //     dispatch(addCv(cv));
    //     dispatch(setCvId(profile_id));
    //     dispatch(setJobId(''));
    //     dispatch(setJobRubricationData([]));
    //     const select_cv = document.querySelector('#cv_list');
      

    // // This will trigger a new render wor the component
    //     select_cv.dispatchEvent(new Event('change', { bubbles: true }));
    //     setShowModal(false);
    //     setCvTitle('');

    //   }
    // });
  }
  const gneerateCvLink = (e) => {
    if (!isPremium && 3 - cvList.length < 1) {
      e.preventDefault();
    }
  }
  return (
    <>
      <div className="flex gap-14">
      <button
        className={`bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mb-4 ${!isPremium && (3 - cvList.length === 0 ? 'cursor-not-allowed' : '')}`}
          type="button"
          disabled={!isPremium ? Boolean(3 - cvList.length <1) : false}
        onClick={() => setShowModal(true)}
      >
        <i className="fa fa-upload"></i> {t('Upload_CV_Btn')} 
      </button>
      <Link
          className={`text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mb-4 ${!isPremium && (3 - cvList.length === 0 ? 'cursor-not-allowed' : '')}`}
          style={{ background: '#10B981' }}
          onClick={(e)=>gneerateCvLink(e)}
        to="/cv"
        
      >
        <i className="fa fa-upload"></i> {t('create_cv')}
      </Link>
      </div>
      {showModal ? (
        
        <form onSubmit={uploadCvHandler}>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {t('Upload_CV_Btn')}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                    <div className=" py-2">
                        <label htmlFor="cv">{t('document')}:</label>            
                        <input type="file" name="cv" id="cv" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                        onChange={fileHandler}
                        />
                    </div>
                    <div className=" py-2">
                        <label htmlFor="title">{t('title_of_cv')}:</label>            
                    <input type="text" name="title" id="title" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                        value={cvTitle}
                        onChange={(e) => setCvTitle(e.target.value)}
                        />
                    </div>
                    
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      {t('close')}
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                    {t('upload')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </form>
      ) : null}
    </>
  );
}