

const ADD_CV = 'add_cv';
const ADD_CV_LIST = 'add_cv_list';



export const addCv = (cv) => ({
    type: ADD_CV,
    cv
});

export const addCvList = (cvList) => ({ 
    type: ADD_CV_LIST,
    cvList
});


const initialState = JSON.parse(localStorage.getItem('cv_list'));

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_CV:
            const { cv } = action;
            const newState = state ? [...state, cv] : [cv];
            localStorage.setItem('cv_list', JSON.stringify(newState));
            return newState;
        case ADD_CV_LIST:
            const { cvList } = action;
            localStorage.setItem('cv_list', JSON.stringify(cvList));
            return cvList;
        default:
        return state;
            
    }
}