import React, { useState } from "react";
import HeaderSection from "components/Sections/HeaderSection";
// import LogoDynaskills from "../../assets/img/logodynaskills.png";
import { faEye, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Step0 from "./steps/Step0";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/step5";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Form() {
    const { t } = useTranslation('site');
    const initialFormData = {
        firstname: "",
        lastname: "",
        actualJob: "",
        postalCode: "",
        city: "",
        country: "",
        phone: "",
        email: "",
        socialLinks: [
            {
                id: 1,
                name: "",
                link: "",
                createdAt: Date.now(),
            }
        ],
        workHistories: [
            {
                id: 1,
                createdAt: Date.now(),
                JobTitle: "",
                employer: "",
                city: "",
                country: "",
                startDate: "",
                endDate: "",
                currentJob: false,
                jobDescription: ""
            }
        ],
        officialTitles: [
            {
                id: 1,
                createdAt: Date.now(),
                officialTitle: "",
                school: "",
                year: ""
            }
        ],
        furtherEducations: [
            {
                id: 1,
                createdAt: Date.now(),
                furtherEducation: "",
                school: "",
                year: ""
            }
        ],
        skills: [
            {
                id: 1,
                createdAt: Date.now(),
                skillName: '',
                rating: 0
            },
            {
                id: 2,
                createdAt: Date.now() + 2,
                skillName: '',
                rating: 0
            },
            {
                id: 3,
                createdAt: Date.now() + 4,
                skillName: '',
                rating: 0
            }
        ],
        languages: [
            {
                id: 1,
                createdAt: Date.now(),
                language: '',
                level: '',
                isNative: false,
            }
        ],
        professionalSummary: "",
        cvTitle: ""
    };
    const generatedCv = localStorage.getItem('generatedCv');
    let form = generatedCv? JSON.parse(generatedCv) : initialFormData;
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState(form);
    const FormPages = [
        {
            title: t('step_1_title'),
            header: t('step_1_header'),
            subheader: t('step_1_subheader')
        },
        {
            title: t('step_2_title'),
            header: t('step_2_header'),
            subheader: t('step_2_subheader')
        },
        {
            title: t('step_3_title'),
            header: t('step_3_header'),
            subheader: t('step_3_subheader')
        },
        {
            title: t('step_4_title'),
            header: t('step_4_header'),
            subheader: t('step_4_subheader')
        },
        {
            title: t('step_5_title'),
            header: t('step_5_header'),
            subheader: t('step_5_subheader')
        },
        {
            title: t('preview'),
            header: "",
            subheader: ""
        }
    ];
    
    const saveCv = () => {
        
        localStorage.setItem('generatedCv', JSON.stringify(formData));
        
        
          
    }

    const PageDisplay = () => {
        if (page === 0) {
            return <Step0 formData={formData} setFormData={setFormData} />;
        } else if (page === 1) {
        return <Step1 formData={formData} setFormData={setFormData} />;
        }else if (page === 2) {
            return <Step2 formData={formData} setFormData={setFormData} />;
        }else if (page === 3) {
            return <Step3 formData={formData} setFormData={setFormData} />;
        }else if (page === 4) {
            return <Step4 formData={formData} setFormData={setFormData} />;
        } else {
            return <Step5 formData={formData} setFormData={setFormData} />;
        }
    };


    const NextButton = () => {
        if (page < 4) {
            return <button className="wizard_btn next_btn" onClick={()=>setPage(page+1)} >{t('next')}</button>
        } else if (page === 4) {
            return <button className="wizard_btn preview_btn"onClick={()=>setPage(page+1)}><FontAwesomeIcon icon={faEye} className="mr-2"/>{t('preview')}</button>
        } else {
            return <button className="wizard_btn next_btn" onClick={()=>saveCv()} >{t('save')}</button>
        }
    }

    return (
        <>
            <HeaderSection header={FormPages[page].header} subheader={FormPages[page].subheader} />
            <div className="container w-full mb-12 xl:mb-0 mx-auto ">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0" style={{ marginTop: "-40px" }}>
                    <Link
                        className="absolute top-5 py-2 text-lg px-5 flex gap-3 bg-red-600 text-white -left-5 flex items-center"
                        to="/matching-jobs"
                    >
                        <FontAwesomeIcon icon={faArrowLeft}  />{t('back')}
                    </Link>
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        
                        <div className="text-center flex flex-wrap justify-center gap-14">
                            {FormPages.map((step, index) => (
                                <button
                                    key={index}
                                    className={index === page ? `text-blueGray-700  font-bold` : `text-blueGray-400  font-bold`}
                                    onClick={()=>setPage(index)}
                                >{step.title}</button>
                            ))}
                        </div>
                    </div>
                    {PageDisplay()}
                    <div className="rounded-b bg-white mb-0 px-6 py-6 ">
                        {page > 0 && <button className="wizard_btn back_btn" onClick={()=>setPage(page-1)} >{t('back')}</button>}
                        <NextButton/>
                    </div>
                </div>
                
            </div>
            
        </>
    );
}

export default Form;
