import sub_categories from 'trans/sub_categories.json'
const translateCategory = (sub_category_id, lang, fallback="") => {

    const rubrication = sub_categories.find(item=>item.sub_category_id==sub_category_id);
    if(rubrication && rubrication[lang]) {
        return rubrication[lang];
    }
    return fallback;
}

export default translateCategory;