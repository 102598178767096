import React from 'react'
import HeaderStats from "components/Headers/HeaderStats.js";
import HorizontalBarChart from "components/Charts/HorizontalBarChart.js";
import RadarChart from "components/Charts/RadarChart.js";
import ActionButons from "components/Buttons/ActionButtons.js";
import { useSelector } from "react-redux";
import Mapper from '../../utils/mapper';
import MapperPercentage from '../../utils/mapperPercentage';
import cvMapper from 'utils/cvMapper';
import trainingMapper from 'utils/trainingMapper';
import { useTranslation } from 'react-i18next';
import translateRubrications from '../../utils/translateRubrications';






function BoostCareerSection() {
    const { t,i18n } = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    
    let { cvId, jobId, resultNumber, cvRubricationData, jobRubricationData, trainingRubricationData } = useSelector(state => state.searchInput);

    cvRubricationData = translateRubrications(cvRubricationData,lang);
    jobRubricationData = translateRubrications(jobRubricationData,lang);
    trainingRubricationData = translateRubrications(trainingRubricationData,lang);
    
    const updatedCvs = useSelector(state => state.updatedCvs);
    const updatedCv = updatedCvs.find(d => d.cvId === cvId && d.jobId === jobId);
    
    const updatedCvRubrications = updatedCv ? updatedCv.cvRubrications : [];


    const sortedTrainingRubrications = trainingRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);
    const trainingQualifications = resultNumber > 0 ? sortedTrainingRubrications.slice(0, 10) : sortedTrainingRubrications;
    const trainingQualificationsData = {
        label: t('Scores_Training'),
        data: trainingQualifications,
        backgroundColor: 'rgb(241, 106, 29)'
    }

    

    const trainingQualificationsHeader = resultNumber > 0 ? t('TOP_10_Score_Training') : t('all_Score_Training');
  

    const sortedJobRubrications = jobRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);
    const jobQualifications = resultNumber > 0 ? sortedJobRubrications.slice(0, 10) : sortedJobRubrications;
    
    
  
    const height = resultNumber === '' ? 250 : 100;
    

    
    const updatedJobCvMapping = Mapper(jobQualifications, updatedCvRubrications);

    const jobCvTrainingMapping = updatedCv ?trainingMapper(jobQualifications, updatedJobCvMapping, trainingRubricationData) :trainingMapper(jobQualifications, cvRubricationData, trainingRubricationData);

    const cvSocres = cvMapper(jobQualifications, cvRubricationData);
    const cvSocresData = {
        label: t('Scores_cv'),
        data: updatedCv ? updatedJobCvMapping : cvSocres,
        backgroundColor: 'rgb(3, 120, 220)'
    }

    const trainingSocres = cvMapper(jobQualifications, trainingRubricationData);
    const trainingSocresData = {
        label: t('Scores_Training'),
        data: trainingSocres,
        backgroundColor: 'rgb(241, 106, 29)'
    }
    
    const jobCvMapping = Mapper(jobQualifications, cvRubricationData);

    const jobMappingPercentage = MapperPercentage(jobCvMapping);
    const updatedJobMappingPercentage = updatedCv ?MapperPercentage(updatedJobCvMapping):'N.A';
    const trainingMappingPercentage = trainingRubricationData.length>0 ?MapperPercentage(jobCvTrainingMapping):'N.A';
    const headerStatsData = [jobMappingPercentage, updatedJobMappingPercentage, trainingMappingPercentage];


    const jobMappingData = {
        label: "",
        data: jobCvMapping,
        backgroundColor: 'rgba(3, 194, 252,0.5)'
    }
    const jobCompletedMappingData = {
        label: "",
        data: updatedCv ? updatedJobCvMapping : jobCvMapping,
        backgroundColor: 'rgba(3, 194, 252,0.5)'
    }

    const jobBoostByTrainingMappingData = {
        label: "",
        data: updatedCv ? jobCvTrainingMapping : updatedJobCvMapping,
        backgroundColor: 'rgba(3, 194, 252,0.5)'
    }
    return (
        <>
            <HeaderStats data={headerStatsData} />
            <div className="flex flex-wrap ">
                <div className="container w-full mb-12 xl:mb-0 px-4 mx-auto">
                    <div className="flex flex-wrap">
                        
                        <RadarChart  header={t('cv_job_mapping')} data={[jobMappingData]} /> 
                        
                        <RadarChart  header={t('Result_after_CV_optimisation')} data={[jobCompletedMappingData]} showButton={true} /> 

                        <RadarChart  header={t('Completed_CV_boosted_by_Training_Menu')} data={[jobBoostByTrainingMappingData]} /> 
                    
                    </div>
                </div>
            </div>
            <ActionButons/>

        </> 
    )
}

export default BoostCareerSection