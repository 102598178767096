
const SET_SECTION = 'set_section';



export const setSection = (number) => ({
    type: SET_SECTION,
    number
});




const initialState = 0;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SECTION:
            const { number } = action
            return number;
        default:
            return state;
            
    }
}