import CoverLetterExamples from "components/Dropdowns/CoverLetterExamples";
import SignatureImg from "components/Inputs/SignatureImg";
import { Link } from "react-router-dom";


const Step1 = ({ formData, setFormData,examples }) => {
    return (
        <div className="pt-10">
            <Link to="cv" className="uppercase text-lg flex gap-2 font-semibold items-center"><i className="fa fa-angle-left " ></i> Return to create new cv</Link>
            <div className="py-10 border-b-2 border-lightBlue-500">
                <h2 className="text-xl font-bold uppercase">Your contact details</h2>
                <div className="flex justify-center">
                    <div className="flex flex-col gap-5 pt-10">
                        <div className="text-xl mb-5">Your Personal Information</div>
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg w-80">Full Name</label>
                            <input 
                                type="text" 
                                className="w-full border-gray-300 rounded"
                                value={formData.fullname}
                                onChange={(e) =>
                                    setFormData({ ...formData, fullname: e.target.value })
                                }
                            />
                        </div>
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg w-80">Address</label>
                            <input 
                                type="text" 
                                className="w-full border-gray-300 rounded"
                                value={formData.address}
                                onChange={(e) =>
                                    setFormData({ ...formData, address: e.target.value })
                                }
                            />
                        </div>
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg w-80">Phone</label>
                            <input 
                                type="text" 
                                className="w-full border-gray-300 rounded"
                                value={formData.phone}
                                onChange={(e) =>
                                    setFormData({ ...formData, phone: e.target.value })
                                }
                            />
                        </div>
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg w-80">Email</label>
                            <input 
                                type="text" 
                                className="w-full border-gray-300 rounded"
                                value={formData.email}
                                onChange={(e) =>
                                    setFormData({ ...formData, email: e.target.value })
                                }
                            />
                        </div>
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg w-80">Date</label>
                            <input
                                type="date"
                                className="w-full border-gray-300 rounded" 
                                value={formData.date}
                                onChange={(e) =>
                                    setFormData({ ...formData, date: e.target.value })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-10 border-b-2 border-lightBlue-500">
                <h2 className="text-xl font-bold uppercase">Recipient</h2>
                <div className="flex justify-center">
                    <div className="flex flex-col gap-5 pt-10">
                        <div className="text-xl mb-5">Information about the company you are applying to</div>
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg w-80">Name of Person & Title</label>
                            <input 
                                type="text" 
                                className="w-full border-gray-300 rounded"
                                value={formData.name_person_and_title}
                                onChange={(e) =>
                                    setFormData({ ...formData, name_person_and_title: e.target.value })
                                }
                            />
                        </div>
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg w-80">Company / Organization</label>
                            <input 
                                type="text" 
                                className="w-full border-gray-300 rounded"
                                value={formData.company}
                                onChange={(e) =>
                                    setFormData({ ...formData, company: e.target.value })
                                }
                            />
                        </div>
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg w-80">Address</label>
                            <input 
                                type="text" 
                                className="w-full border-gray-300 rounded"
                                value={formData.company_address}
                                onChange={(e) =>
                                    setFormData({ ...formData, company_address: e.target.value })
                                }
                            />
                        </div>
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg w-80">Signature</label>
                            <input 
                                type="text" 
                                className="w-full border-gray-300 rounded"
                                value={formData.signature}
                                onChange={(e) =>
                                    setFormData({ ...formData, signature: e.target.value })
                                }
                            />
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <SignatureImg formData={formData} setFormData={setFormData} />
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="py-10 border-b-2 border-lightBlue-500">
                <h2 className="text-xl font-bold uppercase">Contents</h2>
                <CoverLetterExamples formData={formData} setFormData={setFormData} examples={examples} />
                <textarea
                    className="w-full mt-10 "
                    style={{ height: "600px" }}
                    value={formData.content}
                    onChange={(e) =>
                        setFormData({ ...formData, content: e.target.value })
                    }
                />
                    

                
            </div>
        </div>
    )
}

export default Step1;