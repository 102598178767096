import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import ReactStars from "react-rating-stars-component";
import { v4 as uuid } from 'uuid';

import Skill from "./Skill";
import Language from "./Language";
import { useTranslation } from "react-i18next";

function Step3({ formData, setFormData }) {
    const { t } = useTranslation('site');
    
    
    const addNewSkill = () => {
        const oldSkills = formData.skills;
        const newSkill = {
            id: uuid(),
            createdAt: Date.now(),
            skillName: "",
            rating: 0,
        };

        const updatedSkills = [...oldSkills, newSkill];
        setFormData({...formData, skills: updatedSkills});
    }

    const addNewLanguage = () => {
        const oldLanguages = formData.languages;
        const newLanguage = {
            id: uuid(),
            createdAt: Date.now(),
            language: "",
            level: "",
            isNative: false
        };

        const updatedLanguages = [...oldLanguages, newLanguage];
        setFormData({...formData, languages: updatedLanguages});
    }
return (

    <div className="flex-auto px-4 lg:px-10 py-10 ">
            <h2 className="px-4 block text-xl uppercase text-blueGray-600 text-xs font-bold mb-2">
                {t('what_skills_highlight')}
            </h2>
            <div className="flex flex-wrap pt-5">
                
                
                {formData.skills.map(item => <Skill key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
                <div className="w-full lg:w-12/12 px-4 mb-10" style={{marginTop : '-30px'}}>
                <button className="text-sm ml-3 mt-2" type="button" onClick={()=>addNewSkill()} >
                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                    {t('add_another_skill')}
                    </button>
                </div>
                

                {formData.languages.map(item => <Language key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
                <div className="w-full lg:w-12/12 px-4 mb-10" style={{marginTop : '-30px'}}>
                <button className="text-sm ml-3 mt-2" type="button" onClick={()=>addNewLanguage()} >
                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                    {t('add_another_language')}
                    </button>
                </div>
                
            </div>
    </div>
      
);
}

export default Step3;
