import ReactPDF, {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    Font,
  } from "@react-pdf/renderer";
  import { useTranslation } from "react-i18next";
  
  const styles = StyleSheet.create({
    body: {
        fontFamily: 'Oswald',
        paddingBottom: 10,
        fontSize: "14px"
    }
    
  
  });
  
  // const formData = JSON.parse(localStorage.getItem('generatedCv'));
  // console.log(formData);
  
  
  
const B = ({ formData }) => {
    const { t } = useTranslation('site');
    return (
        <Document>
            <Page style={styles.body} size="A4"  >
                <View style={{ padding: '20px 10px' }}>
                    
                    <View style={{display: 'flex', flexDirection: 'column',marginBottom: '40px'}}>
                        <Text style={{marginBottom: '12px', fontSize: '22px',color: "#F24822"}} >{formData.fullname}</Text>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',borderBottomWidth: "2px",borderColor: "#3F3F46", paddingBottom: '8px'}}>
                            <Text>{formData.address}</Text>
                            <Text>{formData.phone}</Text>
                            <Text>{formData.email}</Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px', width: '100%'}}>
                        <View >
                            <Text>{formData.name_person_and_title}</Text>
                            <Text>{formData.company}</Text>
                            <Text>{formData.company_address}</Text>
                        </View>
                        <Text>{formData.date}</Text>

                    </View>
                    <Text style={{whiteSpace: "pre-line",marginBottom: '20px'}}>{formData.content}</Text>
                    <Text>{formData.fullname}</Text> 
                    {formData.signature_file !== "" && <Image src={ formData.signature_file } style={{ width: '30%', padding: '20px 0'}} />}
                </View>
            
            </Page>
        </Document>
    );
}
  
  Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });
  
  
  
  export default B;
  
  
  
  
  
  