

const Step2 = ({ formData, setFormData,page,setPage }) => { 

    return (
        <div className="pt-10">
            <button onClick={() => setPage(page - 1)} className="uppercase text-lg flex gap-2 font-semibold items-center">
                <i className="fa fa-angle-left " ></i>
                Edit your personal information
            </button>
            <div className="py-10 ">
                <div className="text-xl font-semibold">Personalize your cover letter</div>
                <div className="flex justify-between py-5 w-full">
                    <h1 className="text-2xl font-bold uppercase">Choose your cover letter template</h1>
                    <div className="flex items-center text-lg gap-2">
                        Available in: <div className="font-bold  px-2 bg-blueGray-300 rounded-full">PDF</div>
                    </div>
                    
                </div>
                <div className="flex ">
                    <div className="w-1/3 px-5 flex flex-col gap-10">
                        <label className="text-xl font-semibold ">
                            <input
                                type="radio"
                                name="template"
                                value="A"
                                checked={formData.template==="A"}
                                onChange={(e) =>
                                    setFormData({ ...formData, template: e.target.value })
                                }
                            /> Template A
                        </label>
                        <div className="border border-blueGray-200 shadow-lg w-full  py-5 px-2 text-xs">
                            <div className="text-center flex flex-col mb-5">
                                <div className="text-sm font-bold mb-3">{formData.fullname}</div>
                                <span>{formData.address}</span>
                                <span>{formData.phone}</span>
                                <span>{formData.email}</span>
                            </div>
                            <div className="flex justify-between mb-5">
                                <div className="flex flex-col">
                                    <span>{formData.name_person_and_title}</span>
                                    <span>{formData.company}</span>
                                    <span>{formData.company_address}</span>
                                </div>
                                <div>{formData.date}</div>

                            </div>
                            <div className="mb-5" style={{whiteSpace: "pre-line"}}>{formData.content}</div>
                            <div>{formData.fullname}</div>
                        </div>
                    </div>
                    <div className="w-1/3 px-5 flex flex-col gap-10">
                        <label className="text-xl font-semibold ">
                            <input
                                type="radio"
                                name="template"
                                value="B"
                                onChange={(e) =>
                                    setFormData({ ...formData, template: e.target.value })
                                }
                            /> Template B
                        </label>
                        <div className="border border-blueGray-200 shadow-lg w-full  py-5 px-2 text-xs">
                            <div className=" flex flex-col mb-5">
                                <div className="text-sm font-bold mb-3 textOrange">{formData.fullname}</div>
                                <div className="flex justify-between border-gray-700 border-b-2 pb-2">
                                    <span>{formData.address}</span>
                                    <span>{formData.phone}</span>
                                    <span>{formData.email}</span>
                                </div>
                            </div>
                            <div className="flex justify-between mb-5">
                                <div className="flex flex-col">
                                    <span>{formData.name_person_and_title}</span>
                                    <span>{formData.company}</span>
                                    <span>{formData.company_address}</span>
                                </div>
                                <div>{formData.date}</div>

                            </div>
                            <div className="mb-5" style={{whiteSpace: "pre-line"}}>{formData.content}</div>
                            <div>{formData.fullname}</div>
                        </div>
                    </div>
                    <div className="w-1/3 px-5 flex flex-col gap-10">
                        <label className="text-xl font-semibold ">
                            <input
                                type="radio"
                                name="template"
                                value="C"
                                onChange={(e) =>
                                    setFormData({ ...formData, template: e.target.value })
                                }
                            /> Template C
                        </label>
                        <div className="border border-blueGray-200 shadow-lg w-full   text-xs">
                            <div className="text-center text-white flex flex-col mb-5 bg-lightBlue-500 pt-5 pb-2 px-2">
                                <div className="text-sm font-bold mb-3">{formData.fullname}</div>
                                <span>{formData.address}</span>
                                <span>{formData.phone}</span>
                                <span>{formData.email}</span>
                            </div>
                            <div className="px-2 pb-5">
                                <div className="flex justify-between mb-5">
                                    <div className="flex flex-col">
                                        <span>{formData.name_person_and_title}</span>
                                        <span>{formData.company}</span>
                                        <span>{formData.company_address}</span>
                                    </div>
                                    <div>{formData.date}</div>

                                </div>
                                <div className="mb-5" style={{whiteSpace: "pre-line"}}>{formData.content}</div>
                                <div>{formData.fullname}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step2;