import { useState, useRef } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";

function Skill({ id, skillName = "", rating = 0, createdAt, formData, setFormData }) {
    const { t } = useTranslation('site');
    const [skillNameState, setSkillNameState] = useState(skillName);
    const [ratingState, setRatingState] = useState(rating);

    const skillState = {
        id,
        createdAt,
        skillName: skillNameState,
        rating: ratingState,
    };


    const updateSkillName = (e) => {
        setSkillNameState(e.target.value);
        const oldSkills = formData.skills.filter(el => el.id !== id);
        const newSkill = { ...skillState, skill: e.target.value };
        const updatedSkills = [...oldSkills, newSkill].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, skills: updatedSkills });

    }

    const updateRating = (newRating) => {
        
        setRatingState(newRating);
        const oldSkills = formData.skills.filter(el => el.id !== id);
        const newSkill = { ...skillState, rating: newRating };
        const updatedSkills = [...oldSkills, newSkill].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, skills: updatedSkills });

    }


    const removeSkill = () => {
        const oldSkills = formData.skills.filter(el => el.id !== id);
        const updatedSkills = [...oldSkills].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, skills: updatedSkills });
    }
    
    return (
        <>
            <div className="w-full lg:w-6/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={skillNameState}
                            onChange={(e) =>
                                updateSkillName(e)
                            }
                            
                        />
                    </div>
                </div>
                <div className="w-full lg:w-5/12 px-4 ">
                    <div className="relative w-full mb-10">
                    <ReactStars
                        count={5}
                        value={ratingState}
                        onChange={updateRating}
                        size={30}
                        activeColor="#ffd700"
                    />
                    </div>
                </div>
            
                
            <div className="w-full lg:w-1/12 px-4">
                <div className="relative w-full pt-5">
                    {formData.skills.length === 1 ? (
                        <button onClick={() => removeSkill()} disabled="disabled" style={{ cursor: 'not-allowed' }} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "gray" }} />
                        </button>
                    ): (
                        <button onClick={() => removeSkill()} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                        </button>
                    )}
                    
                </div>
            </div>
        </>
    )
} 
export default Skill;