/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import { useEffect, useState } from "react";

import { faArrowLeft, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import MapChart from "components/Charts/MapChart";
import SendMessage from "components/Modals/SendMessage";

import { Link, useLocation } from "react-router-dom";
import axiosClient from "api/axiosClient";
import JobDetailApply from "./JobDetailApply";

import Loading from 'components/Loadings/Loading'
import { useHistory } from "react-router-dom";
import location from "../../assets/icons/location.png"
import internet from "../../assets/icons/internet.png"


const Job = () => {

    const history = useHistory();

    const [showLoader, setShowLoader] = useState(true);
    const search = useLocation().search;
    const job_id = new URLSearchParams(search).get("id");
    
    const [jobId, setJobId] = useState(job_id);
    const [JobData, setJobData] = useState({});

    const jobOptions = useSelector(state => state.jobOptions, shallowEqual);
    const selectedJobs = (jobOptions && jobOptions.length > 0) ? jobOptions.filter(job => job.checked): [];
    
    const { t,i18n } = useTranslation('site');
    const lang = i18n.language.substring(0, 2);

    const [showJobDetailApply, setShowJobDetailApply] = React.useState(false);
    
    

    const getJobDetails = () => {
        setShowLoader(true)
        axiosClient.get(`/JobManagement/GetDetailJob?jobId=${job_id}&language=${lang}&fallbackLanguage=DE`).then((response) => {

            if (response.data ) {
                setJobData(response.data);
            }
            setShowLoader(false)
        })
    }   
    

    useEffect(() => {
        getJobDetails();
    },[search]);
    
    

    
    

    


    if (showLoader) return <Loading />;

    return (
        <>
            <div className="container mx-auto bg-white relative flex flex-col min-w-0 break-words bg-white  w-full mb-6 shadow-lg rounded mb-12 xl:mb-0 py-10  px-4 mt-10 text-black">
            <Link
                className="absolute top-5 py-2 text-lg px-5 flex gap-3 bg-red-600 text-white -left-5 flex items-center"
                to="/matching-jobs"
            >
                <FontAwesomeIcon icon={faArrowLeft}  />{t('back')}
            </Link>
                <div className="p-10">
                    <h3 className="font-semibold text-lg mb-2 px-5"> {t('job_posting_title')} </h3>
                    <h1 className="font-bold text-2xl mb-2 px-5">{JobData?.title}</h1>
                        
                    <div className="relative p-5 mt-10" style={{background: "#F1F5F9"}}>
                        <h2 className="font-bold text-xl">{ t('key_informations') }</h2>
                        <a href="#job-apply" className="absolute right-0 top-0 w-32 h-full text-xl bg-lightBlue-800 text-white font-bold flex items-center justify-center" onClick={(e)=>setShowJobDetailApply(!showJobDetailApply)} >
                            {t('apply')}
                        </a>
                        <div className="flex mt-5">
                            <div className="w-1/2">
                                <ul className="flex flex-col gap-2 text-lg">
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <i className="fas fa-building text-sm" ></i>
                                            <span>
                                                {t('company_name')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{JobData?.company?.name}</p>
                                    </li>
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={location} alt="location" className="w-4 h-4" />
                                            <span>
                                                {t('place_of_work')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{JobData?.city}</p>
                                    </li>
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <i className="fas fa-briefcase text-sm" ></i>
                                            <span>
                                                {t('job_type')}
                                            </span>
                                        </div>
                                        <p className="pl-6">{JobData?.employment}</p>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div className="w-1/2">
                                <ul className="flex flex-col gap-2 text-lg">
                                    <li className="flex flex-col">
                                        <div className="font-bold flex gap-2 items-center">
                                            <img src={internet} alt="website" className="w-4 h-4" />
                                            <span>
                                                {t('website')}
                                            </span>
                                        </div>
                                        {JobData.company.url && <a href={JobData.company.url} target="_blank" className="pl-6">{t('click_here')} <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>}
                                    </li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="flex mt-20">
                        <div className="w-8/12 p-5 overflow-y-auto" style={{background: "#F1F5F9", height:"700px"}}>
                            <h2 className="text-xl font-bold text-center">{t('job_description')}</h2>
                            <p className="mt-10">
                                {JobData?.body}
                            </p>
                            
                        </div>
                        <div className="w-4/12 p-5 overflow-y-auto" style={{height: "700px"}}>
                            <h2 className="text-2xl text-center">{t('your_job_list_preferred')}</h2>
                            <div className="flex flex-col gap-2 mt-10">
                                {selectedJobs.length > 0 && selectedJobs.filter(j=>j.target_profile_id !== job_id).map(j => (
                                    <Link to={`/job-details?id=${j.target_profile_id}`} key={j.target_profile_id} className="w-full p-5" style={{ background: "#F1F5F9" }}>
                                        <h3 className="text-xl">{j?.title}</h3>
                                        <div className="flex gap-2 items-center mt-5"><i className="fas fa-building"></i> <span>{j?.company?.name}</span></div>
                                        <div className="flex gap-2 items-center mt-2"><i className="fas fa-map-marker"></i> <span>{j?.city}</span></div>
                                    </Link>
                                )) }
                                
                                
                            </div>
                        </div>
                    </div>

                    <MapChart header={t('Jobs_loaction')} data={[JobData]} cssClass="w-full pr-5 z-0" shadow={false} />
                    <SendMessage />
                            
                            

                    
                </div>
            </div>
            {showJobDetailApply && <JobDetailApply jobId={job_id} JobData={JobData} />}
        </>
    )
}

export default Job;