import ReactSwitch from "react-switch";
import {useTranslation} from "react-i18next";


const Switch = ({label, handleSwith, checked}) => {
    const {t} =useTranslation('restrictions');
    return (<label className="flex items-center gap-2">
        <span className={"text-blue-600 font-semibold"}>{t(label)}</span>
        <ReactSwitch onChange={handleSwith} checked={checked} checkedIcon={false} uncheckedIcon={false} />
    </label>)
}

export default Switch;