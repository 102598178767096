import React from "react";
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import WorkHistory from "./workHistory";
import { useTranslation } from "react-i18next";

function Step1({ formData, setFormData }) {
    const { t } = useTranslation('site');
    const addNewWorkHistory = () => {
        const oldWorkHistories = formData.workHistories;
        const newWorkHistory = {
            id: uuid(),
            createdAt: Date.now(),
            JobTitle: "",
                employer: "", 
                city: "", 
                country: "", 
                startDate: "", 
                endDate: "", 
                currentJob:false,
                jobDescription: ""
        };
        const updatedWorkHistories = [...oldWorkHistories, newWorkHistory];
        setFormData({...formData, workHistories: updatedWorkHistories});
    }
return (

    
    <div className="flex-auto px-4 lg:px-10 py-10 ">
            {formData.workHistories.map(item=><WorkHistory key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
            <div>
                <button type="button" className="text-sm ml-3" onClick={() =>addNewWorkHistory()}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                    {t('add_more')}
                </button>
            </div>
    </div>
      
);
}

export default Step1;
