import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import OfficialTitle from "./OfficialTitle";
import { v4 as uuid } from 'uuid';
import FurtherEducation from "./FurtherEducation";
import { useTranslation } from "react-i18next";

function Step2({ formData, setFormData }) {
    const { t } = useTranslation('site');

    const addNewOfficialTitle = () => {
        const oldOfficialTitles = formData.officialTitles;
        const newOfficialTitle = {
            id: uuid(),
            createdAt: Date.now(),
            officialTitle: "",
            school: "",
            year: ""
        };

        const updatedOfficialTitles = [...oldOfficialTitles, newOfficialTitle];
        setFormData({...formData, officialTitles: updatedOfficialTitles});
    }

    const addNewFurtherEducation = () => {
        const oldFurtherEducations = formData.furtherEducations;
        const newFurtherEducation = {
            id: uuid(),
            createdAt: Date.now(),
            officialTitle: "",
            school: "",
            year: ""
        };

        const updatedFurtherEducations = [...oldFurtherEducations, newFurtherEducation];
        setFormData({...formData, furtherEducations: updatedFurtherEducations});
    }
    return (

        <div className="flex-auto px-4 lg:px-10 py-10 ">
                {formData.officialTitles.map(item=><OfficialTitle key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
                <div className="w-full lg:w-12/12 px-4 mb-10" style={{marginTop : '-30px'}}>
                    <button type="button" className="text-sm ml-3" onClick={() =>addNewOfficialTitle()}>
                        <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                        {t('add_more_official_title')}
                    </button>
                </div>
                {formData.furtherEducations.map(item=><FurtherEducation key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
                <div className="w-full lg:w-12/12 px-4" style={{marginTop : '-30px'}}>
                    <button type="button" className="text-sm ml-3" onClick={() =>addNewFurtherEducation()}>
                        <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                        {t('add_another_education')}
                    </button>
                </div>
                
        </div>
        
    );
}

export default Step2;
