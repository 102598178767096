import React, { useEffect, useState } from 'react';
import axiosClient from "api/axiosClient";
import UploadCV from "components/Modals/UploadCV"
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addJob } from "redux/reducer/jobListReducer";
import { addJobOptions } from "redux/reducer/jobOptionsReducer";
import { setCvRubricationData } from "redux/reducer/searchReducer";
import { setJobTitle } from "redux/reducer/searchReducer";
import { setShowLoader } from "redux/reducer/searchReducer";
import { setTrainingRubricationData } from "redux/reducer/searchReducer";
import { setJobRubricationData } from "redux/reducer/searchReducer";
import { setTrainingId } from "redux/reducer/searchReducer";
import { setJobId } from "redux/reducer/searchReducer";
import { setCvId } from "redux/reducer/searchReducer";
import { showAddCv } from "redux/reducer/addCvReducer";
import Info from 'components/Modals/Info';
import translateRubrications from 'utils/translateRubrications';
import axiosClientNode from "../../../api/axiosClientNode";

const SelectCv = () => {
    const { t, i18n } = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    const [selectedCv, setSelectedCv] = useState(false);
    const [isFetchingCvRubrications, setIsFetchingCvRubrications]= useState(false);
    const dispatch = useDispatch();
    let {
        cvId,
        resultNumber,
        cvRubricationData
    } = useSelector(state => state.searchInput, shallowEqual);
    const cvList = useSelector(state => state.cvList, shallowEqual);

    const updateCv = (cvId,plusTextCv) => {
        const body = {
            profile_id: cvId,
            plus_text: plusTextCv
        }
        axiosClient.post(`/CVManagement/UpdateCV`, body).then()
    }
    
    const getCvRubrication = (cvId) => {
        setIsFetchingCvRubrications(true);
        dispatch(setShowLoader(true));

        axiosClientNode.get(`/cvs/${cvId}`).then(res => {
            const { rubrication_items } = res.data;
            dispatch(setCvRubricationData(rubrication_items));
            dispatch(setShowLoader(false));


            const translatedCvRubrications = translateRubrications(rubrication_items,lang);
            const sortedCvRubrications = translatedCvRubrications.sort((a, b) => b.ranking_score - a.ranking_score);

            const cvQualifications = resultNumber > 0 ? sortedCvRubrications.slice(0, 10) : sortedCvRubrications;
            const plusTextCv = cvQualifications.map(el=>el.sub_category_name).join(', ')
            // updateCv(cvId, plusTextCv)
            setIsFetchingCvRubrications(false);

        })
        .catch(err => {
            dispatch(setCvRubricationData([]));
            dispatch(setShowLoader(false));
            setIsFetchingCvRubrications(false);

        });

    }

    const selectCvHandler = (e) => {
        
        const selectedCvId = e.target.value;
        dispatch(setCvId(selectedCvId));
        dispatch(setJobId(''));
        dispatch(setJobTitle(''));
        dispatch(addJob([]));
        dispatch(addJobOptions([]));
        dispatch(setJobRubricationData([]));
        dispatch(setTrainingId(''));
        dispatch(setTrainingRubricationData([]));
        setSelectedCv(true)

        if (selectedCvId) {
            getCvRubrication(selectedCvId);

        }
        
    }

    useEffect(()=> {
        if(cvId && cvRubricationData.length === 0 && !selectedCv && !isFetchingCvRubrications) {
            console.log('test')
            getCvRubrication(cvId);
            dispatch(setJobId(''));
            dispatch(setJobTitle(''));
            dispatch(addJob([]));
            dispatch(addJobOptions([]));
            dispatch(setJobRubricationData([]));
            dispatch(setTrainingId(''));
            dispatch(setTrainingRubricationData([]));
        }
    },[cvId, cvRubricationData, selectedCv, isFetchingCvRubrications]);
    /*React.useEffect(() => {
        const select_cv = document.querySelector('#cv_list');
          select_cv.dispatchEvent(new Event('change', { bubbles: true }));
    }, []);*/
    return (
        <>
            <div className="w-full relative">
                <Info cssClasses="absolute right-0 top-2" msg="cv_title_info" />
                <div className="py-2">
                    <label htmlFor="cv_list" className="font-semibold">{t('Select_a_cv')}</label> {t('or')}
                    <button className="ml-2 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-semibold uppercase text-sm px-2 py-1  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" onClick={()=>dispatch(showAddCv(true))}>{t('add_cv')}</button>
                </div>
                <select
                    id="cv_list"
                    className="w-5/12 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline "
                    onChange={selectCvHandler}
                    value={cvId}
                >
                    <option value=""></option>
                    {cvList && cvList.length > 0 && cvList.map((cv, index) => <option key={index} value={cv.profile_id}>{cv.title}</option>)}
                </select>    
            </div>
            
        </>
    )
}
export default SelectCv;