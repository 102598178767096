import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HorizontalBarChart from "../../components/Charts/HorizontalBarChart";
import BarChart from "../../components/Charts/HorizontalBarChartRestriction";
import RestrictionDetailsModal from "../../components/Modals/RestrictionDetailsModal";

const SelectLevel = ({id, defaultValue, title, setAnswers, actionName, selectedValue, setEnableButtons}) => {
    const [level, setLevel] = useState(selectedValue);
    const onChangeHandler = (value) => {
        setEnableButtons(false);
        setLevel(parseInt(value))
        setAnswers((prevState)=> {
            const newItem  = {
                id,
                restriction: `${actionName} ${value} - ${title}`,
                value
            };
            
            return [...prevState.filter((item)=> item.id !== id), newItem];
        })
    }

    return (
        <select className={`${level !== defaultValue && 'text-red-500 text-semibold'}`} value={level} onChange={(e)=>onChangeHandler(e.target.value)} >
            {Array.from({length: 6}, (_, index)=>index).map((item) => {
                return (<option key={item} className={defaultValue !== item ? 'text-red-500 text-semibold': 'text-black text-semibold'} value={item}>{item}</option>)
            })}
        </select>
    )
}
const transformValue = (value) => {
    switch (Number(value)) {
        case 1:
            return '13';
        case 2:
            return '21';
        case 3:
            return '34';
        case 4:
            return '55'
        case 5:
            return '89';
        default:
            return 0;
    }
}
const transformValues = (answers) => {
    if(!answers) {
        return [];
    }
    return answers.map((item)=> {
        const updatedValue = transformValue(item.value);
        return {
            ...item,
            value: updatedValue
        }
    })
}
const CvHealthSection = ({sectionTitle, restrictions,setAnswers, answers, actionName='SetCV', defaultValue = 5, oppositeAnswers, mainColor, secondColor, setEnableButtons}) => {

    const { t } = useTranslation('restrictions');
    const [isOpen, setIsOpen] = useState(false);
    const sectionAnswers = answers.filter((answer)=> restrictions.some((item) => item.id === answer.id));
    // const sectionOppositeAnswers = oppositeAnswers.filter((answer)=> restrictions.some((item) => item.id === answer.id));
    const sectionOppositeAnswers = sectionAnswers.map((answer)=> {
        const oppositeAnswer = oppositeAnswers.find((item)=> item.id === answer.id);
        if(!oppositeAnswer) {
            return {...answer, value: defaultValue === 5 ? 0: 5};
        }
        return oppositeAnswer;
    });
    const transformedSectionAnswers = transformValues(sectionAnswers);
    const transformedSectionOppositeAnswers = transformValues(sectionOppositeAnswers);

    const answersData = {
        label: defaultValue === 5 ?'cv': 'job',
        data: transformedSectionAnswers,
        backgroundColor: mainColor
    }

    const answersOppositeData = {
        label: defaultValue === 5 ?'job': 'cv',
        data: transformedSectionOppositeAnswers,
        backgroundColor: secondColor
    }

    return (
        <div>
            <div className={`relative flex justify-between items-center bg-white w-full py-4 px-8 text-blue-600 shadow-md z-10 ${isOpen ? "rounded-t" : "rounded"}`} onClick={()=>setIsOpen(!isOpen)}>
                <span className="text-xl font-semibold">{t(sectionTitle)}</span>
                <i className={`fa text-xl ${isOpen ? "fa-chevron-down" : "fa-chevron-right"}`}></i>
            </div>
           {isOpen && (
               <>
                 <div className="bg-white z-0 p-8 shadow-md flex flex-col gap-2">
                    {restrictions.map((restriction) => {
                        const selectedValue = answers.find(item => item.id === restriction.id);
                        return (
                            <div key={restriction.id} className="flex justify-between items-center">
                                <span className="text-lg">
                                    {t(restriction.title)} <RestrictionDetailsModal msg={t(restriction.info)} />
                                </span>
                                <SelectLevel id={restriction.id} defaultValue={defaultValue} title={t(restriction.title)} setAnswers={setAnswers} actionName={actionName} selectedValue={selectedValue? selectedValue.value : defaultValue} setEnableButtons={setEnableButtons}/>
                            </div>
                        )
                    })}
                </div>
                <BarChart cssClass="w-full" data={[answersData, answersOppositeData]}  height={100}   showButtons={false} info="top_cv_job_qualifications_info" />
               </>
           )}
        </div>
    );
}

export default CvHealthSection;