import axios from 'axios';

const token = localStorage.getItem('token');
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_DYNASKILLS,
    headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
    }
});

export default axiosClient;