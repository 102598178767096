import React from "react";
import { createPopper } from "@popperjs/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import axiosClient from "api/axiosClientNode";


function useOutsideAlerter(ref,setDropdownPopoverShow) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownPopoverShow(false);
      } else {
        setDropdownPopoverShow(true);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const UserDropdown = () => {
  const {t,i18n}= useTranslation('site');
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  useOutsideAlerter(popoverDropdownRef,setDropdownPopoverShow)
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const logoutHandler = async () => {
    await axiosClient.post(process.env.REACT_APP_BACKEND_DYNASKILLS+'/logout');
    //localStorage.clear();
    window.location.href = "/auth/login";
  }

  const username = localStorage.getItem('username');
  const email = localStorage.getItem('email');
  const shortUsername = username.substring(0, 2);
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex gap-2">
          {/* <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={require("assets/img/user.png").default}
            />
            
          </span> */}
          <div className="w-10 h-10 text-lg bg-blueGray-200 text-lightBlue-500 inline-flex items-center justify-center rounded-full uppercase font-bold">
          {shortUsername}
          </div>
          <span className="text-white text-lg md:text-sm font-semibold">
          {username}
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 px-4 list-none text-left rounded shadow-lg min-w-48 "
        }
      >
        <span className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 "
          }>{ email }</span>
        <a
          href="#logout"
          className={
            "text-sm text-center rounded  text-white py-2 px-4 font-normal block  whitespace-nowrap bg-transparent t bg-red-500"
          }
          onClick={logoutHandler}
        >
          <FontAwesomeIcon icon={faRightFromBracket} style={{ marginRight: "10px" }}/>
          {t('LOGOUT_Btn')}
        </a>
        {/* <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => e.preventDefault()}
        >
          Another action
        </a> */}
        
        {/* <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => e.preventDefault()}
        >
          Seprated link
        </a> */}
      </div>
    </>
  );
};

export default UserDropdown;
