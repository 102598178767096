import React from "react";
import Sidebar from 'components/Sidebar/Sidebar';
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import MatchingJobsCVSection from 'components/Sections/MatchingJobsCVSection';
import { useSelector } from "react-redux";
import Loading from 'components/Loadings/Loading'
import SearchSection from "components/Sections/SearchSection";
import Search from "components/Sections/Search";
import { useTranslation } from 'react-i18next';
import Welcome from "components/Sections/WelcomeSection";
import JobApply from "components/Sections/JobApply"









function MatchingJobs() {
    const { t } = useTranslation('site');
    const { cvId, showLoader, jobTitle } = useSelector(state => state.searchInput);
    

    const [showJobApply, setShowJobApply] = React.useState(false);
    
    return (
        <>
            
            <div className="relative bg-blueGray-100">
                <AdminNavbar title={t('Matchin_Jobs_Header')} />
                <Search showJobApply={showJobApply} setShowJobApply={setShowJobApply}/> 
                {/* <SearchSection headers={[t('Matchin_Jobs_Question')]} /> */}

                
                {/* {showLoader ? <Loader/> : cvId ? <MatchingJobsCVSection /> : ''} */}
                
                {showJobApply && <JobApply />}

            </div>
            <FooterAdmin />
        </>
    );
}

export default MatchingJobs